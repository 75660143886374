import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const slRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-v-besedo
  {
    path: "/pdf-v-besedo",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-v-besedo",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-v-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-v-jpg",
    page: <PDFToJpg />,
  },

  // beseda-v-pdf
  {
    path: "/beseda-v-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/beseda-v-pdf",
    page: <WordToPDF />,
  },

  // slika-v-pdf
  {
    path: "/slika-v-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/slika-v-pdf",
    page: <ImageToPDF />,
  },

  // excel-v-pdf
  {
    path: "/excel-v-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-v-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-v-pdf
  {
    path: "/ppt-v-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-v-pdf",
    page: <PPTToPDF />,
  },

  // stisni-pdf
  {
    path: "/stisni-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/stisni-pdf",
    page: <CompressPDF />,
  },

  // združi-pdf
  {
    path: "/združi-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/združi-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/združi-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/združi-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // razdeli-pdf
  {
    path: "/razdeli-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/razdeli-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/razdeli-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/razdeli-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // bralnik-pdf
  {
    path: "/bralnik-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/bralnik-pdf",
    page: <PDFReader />,
  },

  // izbriši-pdf
  {
    path: "/izbriši-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/izbriši-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/izbriši-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/izbriši-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // zasukaj-pdf
  {
    path: "/zasukaj-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/zasukaj-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/zasukaj-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/zasukaj-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // urejevalnik-pdf
  {
    path: "/urejevalnik-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/urejevalnik-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/urejevalnik-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/urejevalnik-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/stisni-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/stisni-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/združi-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/združi-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/razdeli-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/razdeli-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/zasukaj-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/zasukaj-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/izbriši-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/izbriši-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/bralnik-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/bralnik-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-v-besedo-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-v-besedo-pre",

    page: <PDFToWord />,
  },

  {
    path: "/beseda-v-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/beseda-v-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-v-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-v-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-v-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-v-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-v-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-v-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/slika-v-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/slika-v-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-v-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-v-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-v-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-v-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-in-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-in-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-in-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-in-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-in-datoteka",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-in-datoteka",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-in-datoteka-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-in-datoteka-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-in-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-in-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-in-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-in-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-in-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-in-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-in-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-in-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default slRoutJSON;
