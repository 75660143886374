import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const ruRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },
  //pdf-в-слово
  {
    path: "/pdf-в-слово",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-в-слово",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-в-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-в-jpg",
    page: <PDFToJpg />,
  },

  // слово-в-пдф
  {
    path: "/слово-в-пдф",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/слово-в-пдф",
    page: <WordToPDF />,
  },

  // изображение-в-pdf
  {
    path: "/изображение-в-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/изображение-в-pdf",
    page: <ImageToPDF />,
  },

  // превратить-в-пдф
  {
    path: "/превратить-в-пдф",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/превратить-в-пдф",
    page: <ExcelToPDF />,
  },

  // ppt-в-pdf
  {
    path: "/ppt-в-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-в-pdf",
    page: <PPTToPDF />,
  },

  // сжать-пдф
  {
    path: "/сжать-пдф",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/сжать-пдф",
    page: <CompressPDF />,
  },

  // объединить-pdf
  {
    path: "/объединить-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/объединить-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/объединить-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/объединить-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // разделить-PDF
  {
    path: "/разделить-PDF",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/разделить-PDF",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/разделить-PDF-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/разделить-PDF-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // читатель-пдф
  {
    path: "/читатель-пдф",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/читатель-пдф",
    page: <PDFReader />,
  },

  // удалить-пдф
  {
    path: "/удалить-пдф",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/удалить-пдф",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/удалить-пдф-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/удалить-пдф-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // повернуть-pdf
  {
    path: "/повернуть-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/повернуть-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/повернуть-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/повернуть-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // редактор-пдф
  {
    path: "/редактор-пдф",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/редактор-пдф",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/редактор-пдф-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/редактор-пдф-pre",

    page: <PDFEdit />,
  },

  {
    path: "/сжать-пдф-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/сжать-пдф-pre",

    page: <CompressPDF />,
  },

  {
    path: "/объединить-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/объединить-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/разделить-PDF-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/разделить-PDF-pre",

    page: <SplitPDF />,
  },

  {
    path: "/повернуть-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/повернуть-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/удалить-пдф-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/удалить-пдф-pre",
    page: <DeletePDF />,
  },

  {
    path: "/читатель-пдф-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/читатель-пдф-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-в-слово-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-в-слово-pre",

    page: <PDFToWord />,
  },

  {
    path: "/слово-в-пдф-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/слово-в-пдф-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-в-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-в-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/превратить-в-пдф-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/превратить-в-пдф-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-в-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-в-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/изображение-в-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/изображение-в-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/пдф-в-пнг",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/пдф-в-пнг",

    page: <PDFToJpg />,
  },

  {
    path: "/пнг-в-пдф",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/пнг-в-пдф",

    page: <ImageToPDF />,
  },
  //新增功能

  {
    path: "/pdf-и-pdf-а",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-и-pdf-а",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-и-pdf-а-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-и-pdf-а-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-и-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-и-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-и-файл-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-и-файл-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-и-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-и-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-и-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-и-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-и-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-и-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-и-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-и-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default ruRoutJSON;
