import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const ptRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //PDF para Word
  {
    path: "/PDF para Word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/PDF para Word",
    page: <PDFToWord />,
  },

  //   converter
  {
    path: "/:tag1/converter",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/converter",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/converter",
    page: <MiddlePageOfConvert />,
  },

  //   resultado
  {
    path: "/:tag1/resultado",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/resultado",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/resultado",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/resultado",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/espaço de trabalho de edição de pdf",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/espaço de trabalho de edição de pdf",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf para jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf para jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/palavra para pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/palavra para pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/imagem para pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/imagem para pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel para pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel para pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt para pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt para pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/comprimir pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/comprimir pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/mesclar PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/mesclar PDF",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge pdf work space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge pdf work space",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/dividir pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/dividir pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/dividir espaço de trabalho pdf",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/dividir espaço de trabalho pdf",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/leitor de PDF",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/leitor de PDF",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/excluir pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/excluir pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/excluir espaço de trabalho pdf",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/excluir espaço de trabalho pdf",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/girar pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/girar pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/gire o espaço de trabalho do pdf",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/gire o espaço de trabalho do pdf",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/editor de pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/editor de pdf",
    page: <PDFEdit />,
  },

  //se inscrever
  {
    path: "/se inscrever",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/se inscrever",
    page: <SubscribePage />,
  },

  // perfil
  {
    path: "/perfil",
    page: <Profile />,
    path1: "/perfil/redefinir senha pela senha antiga",
    page1: <ResetPasswordByOldPassword />,
    path2: "/perfil/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/perfil",
    page: <Profile />,
    path1: "/:tag1/perfil/redefinir senha pela senha antiga",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/perfil/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/perfil",
    page: <Profile />,
    path1: "/:tag1/:tag2/perfil/redefinir senha pela senha antiga",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/perfil/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/perfil",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/perfil/redefinir senha pela senha antiga",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/perfil/plano",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/do-editor-de-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/do-editor-de-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/comprimir-de-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: ":tag1/:tag2/:tag3/comprimir-de-pdf-pre",
    page: <CompressPDF />,
  },
  {
    path: "/mesclar-de-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/mesclar-de-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/dividir-de-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/dividir-de-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/gire-a-do-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/gire-a-do-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/excluir-de-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/excluir-de-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/do-leitor-de-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/do-leitor-de-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-para-de-palavras-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-para-de-palavras-pre",

    page: <PDFToWord />,
  },

  // -------
  {
    path: "/de-word-para-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/de-word-para-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/de-ppt-para-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/de-ppt-para-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-para-em-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-para-em-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/de-pdf-para-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/de-pdf-para-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/da-imagem-para-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/da-imagem-para-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/comprimir-de-pdf-pre",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/comprimir-de-pdf-pre",
    page: <CompressPDF />,
  },

  {
    path: "/pdf-para-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-para-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-para-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-para-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-e-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-e-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-e-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-e-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-e-arquivo",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-e-arquivo",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-e-arquivo-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-e-arquivo-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-e-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-e-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-e-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-e-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-e-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-e-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-e-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-e-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default ptRoutJSON;
