import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const srRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //пдф-у-ворд
  {
    path: "/пдф-у-ворд",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/пдф-у-ворд",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/пдф-у-јпг",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/пдф-у-јпг",
    page: <PDFToJpg />,
  },

  // ворд-у-пдф
  {
    path: "/ворд-у-пдф",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/ворд-у-пдф",
    page: <WordToPDF />,
  },

  // слика-у-пдф
  {
    path: "/слика-у-пдф",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/слика-у-пдф",
    page: <ImageToPDF />,
  },

  // екцел-у-пдф
  {
    path: "/екцел-у-пдф",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/екцел-у-пдф",
    page: <ExcelToPDF />,
  },

  // ппт-у-пдф
  {
    path: "/ппт-у-пдф",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ппт-у-пдф",
    page: <PPTToPDF />,
  },

  // компримирај-пдф
  {
    path: "/компримирај-пдф",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/компримирај-пдф",
    page: <CompressPDF />,
  },

  // споји-пдф
  {
    path: "/споји-пдф",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/споји-пдф",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/споји-пдф-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/споји-пдф-work-space",
    page: <MergePDFWorkSpace />,
  },

  // сплит-пдф
  {
    path: "/сплит-пдф",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/сплит-пдф",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/сплит-пдф-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/сплит-пдф-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // пдф-реадер
  {
    path: "/пдф-реадер",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/пдф-реадер",
    page: <PDFReader />,
  },

  // избриши-пдф
  {
    path: "/избриши-пдф",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/избриши-пдф",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/избриши-пдф-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/избриши-пдф-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // ротирај-пдф
  {
    path: "/ротирај-пдф",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/ротирај-пдф",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/ротирај-пдф-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/ротирај-пдф-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // пдф-едитор
  {
    path: "/пдф-едитор",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/пдф-едитор",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/пдф-едитор-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/пдф-едитор-pre",

    page: <PDFEdit />,
  },

  {
    path: "/компримирај-пдф-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/компримирај-пдф-pre",

    page: <CompressPDF />,
  },

  {
    path: "/споји-пдф-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/споји-пдф-pre",

    page: <MergePDF />,
  },

  {
    path: "/сплит-пдф-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/сплит-пдф-pre",

    page: <SplitPDF />,
  },

  {
    path: "/ротирај-пдф-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/ротирај-пдф-pre",

    page: <RotatePDF />,
  },

  {
    path: "/избриши-пдф-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/избриши-пдф-pre",
    page: <DeletePDF />,
  },

  {
    path: "/пдф-реадер-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/пдф-реадер-pre",

    page: <PDFReader />,
  },

  {
    path: "/пдф-у-ворд-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/пдф-у-ворд-pre",

    page: <PDFToWord />,
  },

  {
    path: "/ворд-у-пдф-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/ворд-у-пдф-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ппт-у-пдф-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ппт-у-пдф-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/екцел-у-пдф-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/екцел-у-пдф-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/пдф-у-јпг-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/пдф-у-јпг-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/слика-у-пдф-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/слика-у-пдф-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/пнг-у-пдф",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/пнг-у-пдф",

    page: <ImageToPDF />,
  },

  {
    path: "/пдф-у-пнг",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/пдф-у-пнг",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/пдф-и-пдф-а",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/пдф-и-пдф-а",
    page: <PDFToPDFA />,
  },
  {
    path: "/пдф-и-пдф-а-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/пдф-и-пдф-а-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/пдф-и-фајл",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/пдф-и-фајл",
    page: <PDFToExcel />,
  },
  {
    path: "/пдф-и-фајл-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/пдф-и-фајл-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/пдф-и-птк",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/пдф-и-птк",
    page: <PDFToPPTX />,
  },
  {
    path: "/пдф-и-птк-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/пдф-и-птк-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/хтмл-и-пдф",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/хтмл-и-пдф",
    page: <HtmlToPDF />,
  },
  {
    path: "/хтмл-и-пдф-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/хтмл-и-пдф-pre",
    page: <HtmlToPDF />,
  },
];
export default srRoutJSON;
