import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const afRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },
  //pdf na woord
  {
    path: "/pdf-na-woord",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-na-woord",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/omskakel",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/omskakel",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/omskakel",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/resultaat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/resultaat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/resultaat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/resultaat",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-wysig-werkspasie",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-wysig-werkspasie",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-na-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-na-jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/woord-na-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/woord-na-pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/beeld-na-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/beeld-na-pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel-na-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-na-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt-na-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-na-pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/compress-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/compress-pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/samevoeg-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/samevoeg-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/samevoeg-pdf-werkspasie",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/samevoeg-pdf-werkspasie",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split-pdf-werkspasie",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split-pdf-werkspasie",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/pdf-leser",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf-leser",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/vee-pdf-uit",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/vee-pdf-uit",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/vee pdf-werkspasie-uit",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/vee-pdf-werkspasie-uit",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/draai pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/draai pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/draai pdf werkspasie",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/draai pdf werkspasie",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf redigeerder",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf redigeerder",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profiel
  {
    path: "/profiel",
    page: <Profile />,
    path1: "/profiel/stel wagwoord terug deur ou wagwoord",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profiel/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profiel",
    page: <Profile />,
    path1: "/:tag1/profiel/stel wagwoord terug deur ou wagwoord",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profiel/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profiel",
    page: <Profile />,
    path1: "/:tag1/:tag2/profiel/stel wagwoord terug deur ou wagwoord",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profiel/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profiel",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profiel/stel wagwoord terug deur ou wagwoord",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profiel/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/pdf-redigeerder-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-redigeerder-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/pdf-redigeerder-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-redigeerder-pre",
    page: <PDFEdit />,
  },
  {
    path: "/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/compress-pdf-pre",
    page: <CompressPDF />,
  },
  {
    path: "/samevoeg-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/samevoeg-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/samevoeg-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/samevoeg-pdf-pre",
    page: <MergePDF />,
  },
  {
    path: "/verdeel-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/verdeel-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/verdeel-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/verdeel-pdf-pre",
    page: <SplitPDF />,
  },
  {
    path: "/draai-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/draai-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/draai-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/draai-pdf-pre",
    page: <RotatePDF />,
  },
  {
    path: "/vee-pdf-uit-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/vee-pdf-uit-pre",
    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/vee-pdf-uit-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/vee-pdf-uit-pre",
    page: <DeletePDF />,
  },
  {
    path: "/pdf-leser-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-leser-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/pdf-leser-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-leser-pre",
    page: <PDFReader />,
  },
  {
    path: "/pdf-na-woord-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-na-woord-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/pdf-na-woord-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-na-woord-pre",
    page: <PDFToWord />,
  },
  {
    path: "/woord-na-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/woord-na-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/woord-na-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/woord-na-pdf-pre",
    page: <WordToPDF />,
  },
  {
    path: "/ppt-na-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-na-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/ppt-na-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/ppt-na-pdf-pre",
    page: <PPTToPDF />,
  },
  {
    path: "/excel-na-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-na-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/excel-na-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/excel-na-pdf-pre",
    page: <ExcelToPDF />,
  },
  {
    path: "/pdf-na-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-na-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/pdf-na-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-na-jpg-pre",
    page: <PDFToJpg />,
  },
  {
    path: "/beeld-na-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/beeld-na-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/beeld-na-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/beeld-na-pdf-pre",
    page: <ImageToPDF />,
  },

  {
    path: "/pdf-na-png-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-na-png-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/pdf-na-png-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-na-png-pre",
    page: <PDFToJpg />,
  },

  {
    path: "/pdf-na-png",

    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-na-png",
    page: <PDFToJpg />,
  },

  {
    path: "/png-na-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-na-pdf",

    page: <ImageToPDF />,
  },

  // 新增功能
  {
    path: "/pdf-en-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-en-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-en-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-en-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-en-lêer",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-en-lêer",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-en-lêer-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-en-lêer-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-en-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-en-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-en-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-en-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-en-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-en-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-en-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-en-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default afRoutJSON;
