import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const filRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/pdf sa salita",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf sa salita",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/resulta",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/resulta",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/resulta",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/resulta",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf edit work space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf edit work space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf hanggang jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf hanggang jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/salita sa pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/salita sa pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/larawan sa pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/larawan sa pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel sa pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel sa pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt sa pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt sa pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/compress pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/compress pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/merge pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/merge pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge pdf work space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge pdf work space",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf work space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf work space",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/pdf reader",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf reader",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/delete pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/delete pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/delete pdf work space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/delete pdf work space",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/rotate pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/rotate pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf editor",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset password sa pamamagitan ng lumang password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset password sa pamamagitan ng lumang password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset password sa pamamagitan ng lumang password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plano",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset password sa pamamagitan ng lumang password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plano",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/pdf-editor-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-editor-pre",

    page: <PDFEdit />,
  },

  {
    path: "/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/compress-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/merge-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/merge-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/split-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/rotate-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/rotate-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/delete-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/delete-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-reader-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-reader-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-sa-salita-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-sa-salita-pre",

    page: <PDFToWord />,
  },

  {
    path: "/salita-sa-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/salita-sa-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-sa-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-sa-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-sa-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-sa-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-hanggang-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-hanggang-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/larawan-sa-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/larawan-sa-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-hanggang-png-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-hanggang-png-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/png-hanggang-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-hanggang-pdf-pre",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-at-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-at-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-at-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-at-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-at-file",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-at-file",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-at-file-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-at-file-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-at-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-at-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-at-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-at-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-at-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-at-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-at-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-at-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default filRoutJSON;
