import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const jaRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/pdf から単語へ",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf から単語へ",
    page: <PDFToWord />,
  },

  //   変換
  {
    path: "/:tag1/変換",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/変換",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/変換",
    page: <MiddlePageOfConvert />,
  },

  //   結果
  {
    path: "/:tag1/結果",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/結果",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/結果",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/結果",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf 編集ワークスペース",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf 編集ワークスペース",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf から jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf から jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/word to pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word to pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/画像をPDFに",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/画像をPDFに",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel to pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel to pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt から pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt から pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/pdfを圧縮",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/pdfを圧縮",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/merge pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/merge pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge pdf ワークスペース",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge pdf ワークスペース",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf ワークスペース",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf ワークスペース",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/pdf リーダー",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf リーダー",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/pdfを削除",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/pdfを削除",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/delete pdf ワークスペース",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/delete pdf ワークスペース",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/PDFを回転",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/PDFを回転",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate pdf ワークスペース",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate pdf ワークスペース",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf エディタ",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf エディタ",
    page: <PDFEdit />,
  },

  //購読
  {
    path: "/購読",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/購読",
    page: <SubscribePage />,
  },

  // プロファイル
  {
    path: "/プロファイル",
    page: <Profile />,
    path1: "/プロファイル/古いパスワードでパスワードをリセット",
    page1: <ResetPasswordByOldPassword />,
    path2: "/プロファイル/プラン",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/プロファイル",
    page: <Profile />,
    path1: "/:tag1/プロファイル/古いパスワードでパスワードをリセット",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/プロファイル/プラン",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/プロファイル",
    page: <Profile />,
    path1: "/:tag1/:tag2/プロファイル/古いパスワードでパスワードをリセット",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/プロファイル/プラン",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/プロファイル",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/プロファイル/古いパスワードでパスワードをリセット",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/プロファイル/プラン",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/pdf-エディタ-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-エディタ-pre",

    page: <PDFEdit />,
  },

  {
    path: "/pdfを圧縮-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/pdfを圧縮-pre",

    page: <CompressPDF />,
  },

  {
    path: "/merge-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/merge-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/split-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/PDFを回転-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/PDFを回転-pre",

    page: <RotatePDF />,
  },

  {
    path: "/pdfを削除-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/pdfを削除-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-リーダー-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-リーダー-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-から単語へ-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-から単語へ-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-to-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-から-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-から-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-から-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-から-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/画像をPDFに-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/画像をPDFに-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/PNGからPDFへ",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/PNGからPDFへ",

    page: <ImageToPDF />,
  },

  {
    path: "/PDFからPNGへ",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/PDFからPNGへ",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-と-pdf-あ",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-と-pdf-あ",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-と-pdf-あ-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-と-pdf-あ-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-と-えxせl",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-と-えxせl",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-と-えxせl-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-と-えxせl-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-と-っptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-と-っptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-と-っptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-と-っptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-と-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-と-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-と-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-と-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default jaRoutJSON;
