import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const beRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/pdf ў word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf ў word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/пераўтварыць",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/пераўтварыць",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/пераўтварыць",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/вынік",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/вынік",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/вынік",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/вынік",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf edit work space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf edit work space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf ў jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf ў jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/word у pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word у pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/малюнак у pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/малюнак у pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel у pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel у pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt у pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt у pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/сціснуць pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/сціснуць pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/аб'яднаць pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/аб'яднаць pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/аб'яднаць pdf працоўная прастора",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/аб'яднаць pdf працоўная прастора",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/разбіць pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/разбіць pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf рабочая прастора",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf рабочая прастора",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/pdf чытач",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf чытач",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "выдаліць pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/выдаліць pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/выдаліць працоўную прастору pdf",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/выдаліць працоўную прастору pdf",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/павярнуць pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/павярнуць pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/паварот працоўнай прасторы pdf",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/паварот працоўнай прасторы pdf",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf рэдактар",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf рэдактар",
    page: <PDFEdit />,
  },

  //subscribe
  {
    path: "/падпісацца",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/падпісацца",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/профіль",
    page: <Profile />,
    path1: "/профіль/скід пароля старым паролем",
    page1: <ResetPasswordByOldPassword />,
    path2: "/профіль/план",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/профіль",
    page: <Profile />,
    path1: "/:tag1/профіль/скід пароля старым паролем",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/профіль/план",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/профіль",
    page: <Profile />,
    path1: "/:tag1/:tag2/профіль/скід пароля старым паролем",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/профіль/план",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/профіль",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/профіль/скід пароля старым паролем",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/профіль/план",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/pdf-рэдактар-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-рэдактар-pre",

    page: <PDFEdit />,
  },

  {
    path: "/сціснуць-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/сціснуць-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/аб'яднаць-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/аб'яднаць-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/разбіць-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/разбіць-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/павярнуць-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/павярнуць-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/выдаліць-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/выдаліць-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-чытач-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-чытач-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-ў-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-ў-word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-у-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-у-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-у-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-у-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-у-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-у-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-ў-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-ў-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/малюнак-у-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/малюнак-у-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-ў-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-ў-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-ў-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-ў-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-і-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-і-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-і-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-і-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-і-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-і-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-і-файл-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-і-файл-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-і-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-і-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-і-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-і-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-і-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-і-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-і-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-і-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default beRoutJSON;
