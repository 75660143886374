import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const ltRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-į-word
  {
    path: "/pdf-į-word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-į-word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-į-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-į-jpg",
    page: <PDFToJpg />,
  },

  // word-į-pdf
  {
    path: "/word-į-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word-į-pdf",
    page: <WordToPDF />,
  },

  // vaizdas-į-pdf
  {
    path: "/vaizdas-į-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/vaizdas-į-pdf",
    page: <ImageToPDF />,
  },

  // Excel-į-pdf
  {
    path: "/Excel-į-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/Excel-į-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-į-pdf
  {
    path: "/ppt-į-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-į-pdf",
    page: <PPTToPDF />,
  },

  // suspausti-pdf
  {
    path: "/suspausti-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/suspausti-pdf",
    page: <CompressPDF />,
  },

  // sujungti-pdf
  {
    path: "/sujungti-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/sujungti-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/sujungti-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/sujungti-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // padalinti-pdf
  {
    path: "/padalinti-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/padalinti-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/padalinti-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/padalinti-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-skaitytuvas
  {
    path: "/pdf-skaitytuvas",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf-skaitytuvas",
    page: <PDFReader />,
  },

  // ištrinti-pdf
  {
    path: "/ištrinti-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/ištrinti-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/ištrinti-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/ištrinti-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // pasukti-pdf
  {
    path: "/pasukti-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/pasukti-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/pasukti-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pasukti-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-redaktorius
  {
    path: "/pdf-redaktorius",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-redaktorius",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/pdf-redaktorius-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-redaktorius-pre",

    page: <PDFEdit />,
  },

  {
    path: "/suspausti-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/suspausti-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/sujungti-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/sujungti-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/padalinti-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/padalinti-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/pasukti-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/pasukti-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/ištrinti-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/ištrinti-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-skaitytuvas-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-skaitytuvas-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-į-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-į-word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-į-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-į-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-į-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-į-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/Excel-į-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/Excel-į-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-į-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-į-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/vaizdas-į-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/vaizdas-į-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/png-į-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-į-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-į-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-į-png",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-ir-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-ir-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-ir-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-ir-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-ir-failas",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-ir-failas",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-ir-failas-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-ir-failas-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-ir-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-ir-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-ir-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-ir-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-ir-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-ir-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-ir-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-ir-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default ltRoutJSON;
