// import { useTranslation } from 'react-i18next';
// 工具类别
export const toolCategory = [
  { ID: 0, name: "Convert from PDF" },
  { ID: 1, name: "Convert to PDF" },
  { ID: 2, name: "Edit PDF" },
];

// 工具列表
// const { t } = useTranslation()
// const path = encodeURI('/' + t('PDF to Word'))
// console.log(path);
// const path = encodeURI('/PDF를 워드로')
// const path = encodeURI('/PDF转Word')
export const toolList = [
  {
    ID: 0,
    categoryID: 0,
    key: "0-0-pdf2word",
    name: "PDFToWord",
    path: "/pdf-to-word",
    pathPreview: "/pdf-to-word-pre",
    domain: "pdf2word",
    icon: "icon_pdf_to_word.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_word_new.svg",
    imgAlt: "Quickly convert PDF files to easily editable Word files.",
    color: "#3D99F5",
    inputFileType: ["pdf"],
    outputFileType: ["doc", "docx"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_word.svg",
    beforeUploadInform:
      "Convert PDF to Microsoft Word format in seconds with simple drag and drop or a click to upload. Free to use, fast conversions, incredible accuracy, no watermark, no email registration required.",
    context: {
      name: {
        key: "tool.pdf2word.name",
        default: "PDF to Word",
      },
      description: {
        key: "tool.pdf2word.description",
        default:
          "Easily convert your PDF files into easy to edit DOC and DOCX documents. The converted WORD document is almost 100% accurate.",
      },
      title: {
        key: "tool.pdf2word.title",
        default: "PDF to Word",
      },
      subtitle: {
        key: "tool.pdf2word.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.pdf2word.tipTitle",
          default: ["How to", "Convert a PDF Document", "to Word"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2word.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2word.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2word.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2word.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2word.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  {
    ID: 1,
    categoryID: 1,
    key: "1-0-word2pdf",
    name: "WordToPDF",
    path: "/word-to-pdf",
    pathPreview: "/word-to-pdf-pre",
    domain: "word2pdf",
    icon: "icon_word_to_pdf.svg",
    beforeToolIcon: "icon_word_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Convert Word files to PDF files quickly and directly.",
    color: "#3D99F5",
    inputFileType: ["doc", "docx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_word_pdf.svg",
    beforeUploadInform:
      "Turn Word Documents to PDF in seconds. Instantly convert your DOC and DOCX files to PDFs. No watermark and easy to share.",
    context: {
      name: {
        key: "tool.word2pdf.name",
        default: "Word to PDF",
      },
      description: {
        key: "tool.word2pdf.description",
        // default: "Convert Word files to PDF files quickly and directly.",
        default: "Make DOC and DOCX files easy to read by converting them to PDF.",
      },
      title: {
        key: "tool.word2pdf.title",
        default: "Word to PDF",
      },
      subtitle: {
        key: "tool.word2pdf.subtitle",
        default: "Convert your Word to PDF",
      },
      tip: {
        title: {
          key: "tool.word2pdf.tipTitle",
          default: ["How to", "convert Word", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.word2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.word2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.word2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.word2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.word2pdf.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  {
    ID: 2,
    categoryID: 2,
    key: "2-2-compresspdf",
    name: "CompressPDF",
    path: "/compress-pdf",
    pathPreview: "/compress-pdf-pre",
    domain: "compresspdf",
    icon: "icon_compress_pdf.svg",
    beforeToolIcon: "icon_compress_pdf.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Quickly and directly reduce PDF file size.",
    color: "#F23030",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, 12, 11],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_compress_pdf.svg",
    beforeUploadInform:
      "Easily to compress PDF files up to 99%.Reduce PDF file size while highest PDF quality.Free to use and easy to share.",
    context: {
      name: {
        key: "tool.compresspdf.name",
        default: "Compress PDF",
      },
      description: {
        key: "tool.compresspdf.description",
        // default: "Quickly and directly reduce PDF file size.",
        default: "Reduce file size while optimizing for maximal PDF quality.",
      },
      title: {
        key: "tool.compresspdf.title",
        default: "Compress PDF",
      },
      subtitle: {
        key: "tool.compresspdf.subtitle",
        default: "Reduce the size of your PDF online",
      },
      tip: {
        title: {
          key: "tool.compresspdf.tipTitle",
          default: ["How to", "compress", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.compresspdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.compresspdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.compresspdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.compresspdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  {
    ID: 3,
    categoryID: 1,
    key: "1-1-img2pdf",
    name: "ImageToPDF",
    path: "/image-to-pdf",
    pathPreview: "/image-to-pdf-pre",
    domain: "img2pdf",
    icon: "icon_image_to_pdf.svg",
    beforeToolIcon: "icon_img.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Convert Image to PDF files quickly and directly.",
    color: "#FFB700",
    inputFileType: ["avif", "cur", "jfif", "bmp", "gif", "jpg", "jpeg", "png", "svg", "webp"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_image_pdf.svg",
    beforeUploadInform:
      "Upload or drop image file. We accept PNG, as well as JPG or other formats. Convert images to PDF in seconds. It's easily to download and share.",
    context: {
      name: {
        key: "tool.img2pdf.name",
        default: "Image to PDF",
      },
      description: {
        key: "tool.img2pdf.description",
        // default: "Convert Image to PDF files quickly and directly.",
        default: "Convert images to PDF in seconds. Easily adjust orientation and margins.",
      },
      title: {
        key: "tool.img2pdf.title",
        default: "Image to PDF",
      },
      subtitle: {
        key: "tool.img2pdf.subtitle",
        default: "The best web app to convert Image to PDF",
      },
      tip: {
        title: {
          key: "tool.img2pdf.tipTitle",
          default: ["How to", "Convert Image", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.img2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.img2pdf.tip1.text",
              default:
                "Upload steps: Upload Image files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.img2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.img2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.img2pdf.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  {
    ID: 4,
    categoryID: 2,
    key: "2-4-mergepdf",
    name: "MergePDF",
    path: "/merge-pdf",
    pathPreview: "/merge-pdf-pre",
    domain: "mergepdf",
    icon: "icon_merge.svg",
    beforeToolIcon: "icon_merge_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Can quickly and directly merge multiple PDFs into one PDF file.",
    color: "#7961F2",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_merge_pdf.svg",
    beforeUploadInform:
      "Merge PDF online, easy to use. Merge in the order you want. With a few simple clicks, you can merge multiple PDF files into one file. Add more files, rotate or delete files, if needed.",
    context: {
      name: {
        key: "tool.mergepdf.name",
        default: "Merge PDF",
      },
      description: {
        key: "tool.mergepdf.description",
        default: "Combine PDFs in the order you want with the easiest PDF merger available.",
        // default: "Can quickly and directly merge multiple PDFs into one PDF file.",
      },
      title: {
        key: "tool.mergepdf.title",
        default: "Merge PDF",
      },
      subtitle: {
        key: "tool.mergepdf.subtitle",
        default: "The easiest way to combine PDF Files",
      },
      tip: {
        title: {
          key: "tool.mergepdf.tipTitle",
          default: ["How to", "merge", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.mergepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.mergepdf.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },
  {
    ID: 5,
    categoryID: 2,
    key: "2-3-splitpdf",
    name: "SplitPDF",
    path: "/split-pdf",
    pathPreview: "/split-pdf-pre",
    domain: "splitpdf",
    icon: "icon_split.svg",
    beforeToolIcon: "icon_split_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Quickly and directly split a PDF into multiple PDF files.",
    color: "#7961F2",
    inputFileType: ["pdf"],
    outputFileType: ["pdf", "zip"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_split_pdf.svg",
    beforeUploadInform:
      "Instantly split your PDF into individual pages, or extract specific pages to form new PDF documents. You can also add and extract pages from multiple PDFs at the same time.",
    context: {
      name: {
        key: "tool.splitpdf.name",
        default: "Split PDF",
      },
      description: {
        key: "tool.splitpdf.description",
        // default: "Quickly and directly split a PDF into multiple PDF files.",
        default: "Separate one page or a whole set for easy conversion into independent PDF files.",
      },
      title: {
        key: "tool.splitpdf.title",
        default: "Split PDF",
      },
      subtitle: {
        key: "tool.splitpdf.subtitle",
        default: "An easy way to extract pages from PDF",
      },
      tip: {
        title: {
          key: "tool.splitpdf.tipTitle",
          default: ["How to", "split", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.splitpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  {
    ID: 6,
    categoryID: 1,
    key: "1-3-excel2pdf",
    name: "ExcelToPDF",
    path: "/excel-to-pdf",
    pathPreview: "/excel-to-pdf-pre",
    domain: "excel2pdf",
    icon: "icon_excel_to_pdf.svg",
    beforeToolIcon: "icon_excel_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Convert Excel files to PDF files quickly and directly.",
    color: "#FFB700",
    inputFileType: ["xls", "xlsx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_excel_pdf.svg",
    beforeUploadInform:
      "Quickly convert Microsoft Excel spreadsheets to PDF documents for easy sharing and storage. We convert Excel to PDF on all platforms, no matter what operating system you're using - Windows, Mac or Linux.",
    context: {
      name: {
        key: "tool.excel2pdf.name",
        default: "Excel to PDF",
      },
      description: {
        key: "tool.excel2pdf.description",
        // default: "Convert Excel files to PDF files quickly and directly.",
        default: "Make EXCEL spreadsheets easy to read by converting them to PDF.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Excel to PDF",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert your Excel spreadsheet to PDF",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert PPT", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  {
    ID: 7,
    categoryID: 1,
    key: "1-2-ppt2pdf",
    name: "PPTToPDF",
    path: "/ppt-to-pdf",
    pathPreview: "/ppt-to-pdf-pre",
    domain: "ppt2pdf",
    icon: "icon_ppt_to_pdf.svg",
    beforeToolIcon: "icon_ppt_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Convert PPT files to PDF files quickly and directly.",
    color: "#FF8000",
    inputFileType: ["ppt", "pptx"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_ppt_pdf.svg",
    beforeUploadInform:
      "Convert PPT files to PFDs in seconds with simple drag and drop or a click to upload. Free to use, fast conversions, incredible accuracy, no watermark, no email registration required.",
    context: {
      name: {
        key: "tool.ppt2pdf.name",
        default: "PPT to PDF",
      },
      description: {
        key: "tool.ppt2pdf.description",
        // default: "Convert PPT files to PDF files quickly and directly.",
        default: "Make PPT and PPTX slideshows easy to view by converting them to PDF.",
      },
      title: {
        key: "tool.ppt2pdf.title",
        default: "PPT to PDF",
      },
      subtitle: {
        key: "tool.ppt2pdf.subtitle",
        default: "Convert your PowerPoint to PDF",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert PPT", "to PDF"],
        },
        body: [
          {
            title: {
              key: "tool.ppt2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.ppt2pdf.tip1.text",
              default:
                "Upload steps: Upload office files or drag and drop files to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.ppt2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.ppt2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.ppt2pdf.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  {
    ID: 8,
    categoryID: 2,
    key: "2-1-pdfreader",
    name: "PDFReader",
    path: "/pdf-reader",
    pathPreview: "/pdf-reader-pre",
    domain: "pdfreader",
    icon: "icon_reader.svg",
    beforeToolIcon: "icon_reader_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Can open PDF files quickly,not stuck,easy to read.",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_reader.svg",
    beforeUploadInform:
      "This online free PDF reader app lets you view, comment, fill and sign PDF forms. Read PDF quickly and efficiently on all platforms, no matter what operating system you're using - Windows, Mac or Linux.",
    context: {
      name: {
        key: "tool.pdfreader.name",
        default: "PDF Reader",
      },
      description: {
        key: "tool.pdfreader.description",
        default: "Quickly open PDFs for reading and viewing.",
        // default: "Can open PDF files quickly,not stuck,easy to read.",
      },
      title: {
        key: "tool.pdfreader.title",
        default: "PDF Reader",
      },
      subtitle: {
        key: "tool.pdfreader.subtitle",
        default: "Display, print, and share PDFs online",
      },
      tip: {
        title: {
          key: "tool.pdfreader.tipTitle",
          default: ["How to", "use", "reader"],
        },
        body: [
          {
            title: {
              key: "tool.pdfreader.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdfreader.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdfreader.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdfreader.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdfreader.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  {
    ID: 9,
    categoryID: 2,
    key: "2-6-deletepdf",
    name: "DeletePDF",
    path: "/delete-pdf",
    pathPreview: "/delete-pdf-pre",
    domain: "deletepdf",
    icon: "icon_delete.svg",
    beforeToolIcon: "icon_delete_new.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Quickly delete one or more pages from your PDF.",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_delete_pdf.svg",
    beforeUploadInform:
      "Our online PDF tool can be used to remove single or multiple pages from your PDF documents. There are no file size restrictions at all. Simply upload, modify and save your file.",
    context: {
      name: {
        key: "tool.deletepdf.name",
        default: "Delete PDF",
      },
      description: {
        key: "tool.deletepdf.description",
        // default: "Quickly delete one or more pages from your PDF.",
        default: "Delete the corresponding PDF pages according to your needs.",
      },
      title: {
        key: "tool.deletepdf.title",
        default: "Delete PDF Pages",
      },
      subtitle: {
        key: "tool.deletepdf.subtitle",
        default: "Remove pages from your PDF online",
      },
      tip: {
        title: {
          key: "tool.deletepdf.tipTitle",
          default: ["How to", "delete", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.deletepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.deletepdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.deletepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.deletepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.deletepdf.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },
  {
    ID: 10,
    categoryID: 2,
    key: "2-5-rotatepdf",
    name: "RotatePDF",
    path: "/rotate-pdf",
    pathPreview: "/rotate-pdf-pre",
    domain: "rotatepdf",
    icon: "icon_rotate.svg",
    beforeToolIcon: "icon_rotate_new.svg",
    imgAlt: "Quickly realize PDF single page,or entire PDF rotation.",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_rotate_pdf.svg",
    beforeUploadInform:
      "Our online PDF tool can be used to rotate single or multiple pages from your PDF documents. There are no file size restrictions at all. Simply upload, modify and save your file.",
    context: {
      name: {
        key: "tool.rotatepdf.name",
        default: "Rotate PDF",
      },
      description: {
        key: "tool.rotatepdf.description",
        // default: "Quickly realize PDF single page,or entire PDF rotation.",
        default: "Rotate PDF according to reading habits.",
      },
      title: {
        key: "tool.rotatepdf.title",
        default: "Rotate PDF",
      },
      subtitle: {
        key: "tool.rotatepdf.subtitle",
        default: "Realize PDF single page or entire PDF rotation",
      },
      tip: {
        title: {
          key: "tool.rotatepdf.tipTitle",
          default: ["How to", "rotate", "PDF"],
        },
        body: [
          {
            title: {
              key: "tool.rotatepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.rotatepdf.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.rotatepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.rotatepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.rotatepdf.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  {
    ID: 11,
    categoryID: 0,
    key: "0-1-pdf2img",
    name: "PDFToJPG",
    path: "/pdf-to-jpg",
    pathPreview: "/pdf-to-jpg-pre",
    domain: "pdf2img",
    icon: "icon_pdf_to_image.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_img.svg",
    imgAlt: "Convert PDF files to images quickly and directly.",
    color: "#FFB700",
    inputFileType: ["pdf"],
    outputFileType: ["jpg", "zip"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_image.svg",
    beforeUploadInform:
      "Convert PDF to  high-quality JPG images in seconds. It's easily to download and share.There is no file size limit and no registration is required.",
    context: {
      name: {
        key: "tool.pdf2img.name",
        default: "PDF to JPG",
      },
      description: {
        key: "tool.pdf2img.description",
        // default: "Convert PDF files to images quickly and directly.",
        default: "Convert each PDF page into a JPG or extract all images contained in a PDF.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Convert PDF to JPG",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Extract images or save each page from PDF to JPG",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "convert PDF", "to JPG"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },
  {
    ID: 12,
    categoryID: 2,
    key: "2-0-editorpdf",
    name: "PDFEditor",
    path: "/pdf-editor",
    pathPreview: "/pdf-editor-pre",
    domain: "editorpdf",
    icon: "icon_edit.svg",
    beforeToolIcon: "icon_editor.svg",
    arrow: "none",
    afterToolIcon: "icon_pdf_new.svg",
    imgAlt: "Add text highlights shapes images and signature to your pdf.",
    color: "#0FC0C5",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_editor.svg",
    beforeUploadInform:
      "Edit text and add images, shapes, highlights to your PDFs.The easiest way to edit your work and get more done in less time.",
    context: {
      name: {
        key: "tool.editorpdf.name",
        default: "PDF Editor",
      },
      description: {
        key: "tool.editorpdf.description",
        default:
          "Add text, images, shapes or freehand annotations to a PDF document. Edit the size, font, and color of the added content.",
        // default: "Add text highlights shapes images and signature to your pdf.",
      },
      title: {
        key: "tool.editorpdf.title",
        default: "PDF Editor",
      },
      subtitle: {
        key: "tool.editorpdf.subtitle",
        default: "The best online tool to edit PDF documents",
      },
      tip: {
        title: {
          key: "tool.editorpdf.tipTitle",
          default: ["How to", "edit PDF", "online"],
        },
        body: [
          {
            title: {
              key: "tool.editorpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.editorpdf.tip1.text",
              default: "Drag and drop your PDF document into the PDF Editor.",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.editorpdf.tip2.text",
              default:
                "Add text, images, shapes or freehand annotations as you wish.You can also edit the size, font, and color of the added content.",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.editorpdf.tip3.text",
              default: "Click share button or download button to save the change pdf.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.editorpdf.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },
  // {
  //   ID: 13,
  //   categoryID: 0,
  //   key: "1-4-html2pdf",
  //   name: "HTMLToPDF",
  //   path: "/html-to-pdf",
  //   pathPreview: "/html-to-pdf-pre",
  //   domain: "html2pdf",
  //   icon: "icon_html_to_pdf.svg",
  //   beforeToolIcon: "icon_html.svg",
  //   arrow: "icon_arrow.svg",
  //   afterToolIcon: "icon_pdf_new.svg",
  // beforeUploadIcon: "/images/beforeUpload/show_select_html_pdf.svg",
  // beforeUploadInform:"Convert HTML pages to PDF documents for free with our HTML to PDF converter. Transform any web page to a PDF document. Easy, fast and without registration.",
  //   color: "#FFB700",
  //   inputFileType: ["html"],
  //   outputFileType: ["jpg", "zip"],
  //   recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
  //   isAutoFunction: true,
  //   context: {
  //     name: {
  //       key: "tool.pdf2img.name",
  //       default: "HTML to PDF",
  //     },
  //     description: {
  //       key: "tool.pdf2img.description",
  //       default: "Convert webpages in HTML to PDF. Copy and paste the URL of the page you want and convert it to PDF with a click.",
  //     },
  //     title: {
  //       key: "tool.pdf2img.title",
  //       default: "Convert HTML to PDF",
  //     },
  //     subtitle: {
  //       key: "tool.pdf2img.subtitle",
  //       default: "Write the website URL",
  //     },
  //     tip: {
  //       title: {
  //         key: "tool.pdf2img.tipTitle",
  //         default: ["How to", "convert HTML", "to PDF"],
  //       },
  //       body: [
  //         {
  //           title: {
  //             key: "tool.pdf2img.tip1.title",
  //             default: "Tip1",
  //             color: "#D9413D",
  //           },
  //           text: {
  //             key: "tool.pdf2img.tip1.text",
  //             default:
  //               "Upload steps: Just enter a web address and then click the confirm button and then you can download the files you need on the download page.",
  //           },
  //         },
  //         {
  //           title: {
  //             key: "tool.pdf2img.tip2.title",
  //             default: "Tip2",
  //             color: "#041699",
  //           },
  //           text: {
  //             key: "tool.pdf2img.tip2.text",
  //             default:
  //               "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
  //           },
  //         },
  //         {
  //           title: {
  //             key: "tool.pdf2img.tip3.title",
  //             default: "Tip3",
  //             color: "#C2AE18",
  //           },
  //           text: {
  //             key: "tool.pdf2img.tip3.text",
  //             default:
  //               "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
  //           },
  //         },
  //       ],
  //     },
  //     successfulTitle: {
  //       key: "tool.pdf2img.successfulTitle",
  //       default: "PDF to JPG Convert Successful",
  //     },
  //   },
  // },
  {
    ID: 14,
    categoryID: 0,
    key: "0-3-pdf2pdfa",
    name: "PDFToPDFA",
    path: "/pdf-to-pdfa",
    pathPreview: "/pdf-to-pdfa-pre",
    domain: "pdf2pdfa",
    icon: "icon_pdf_to_pdfa.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_pdfa.svg",
    beforeUploadInform:
      "Free online file converter for you to change your PDF to PDF/A format. Simply select the file you want to convert, and use the online",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdfa.svg",
    color: "#FFB700",
    inputFileType: ["pdf"],
    outputFileType: ["pdf"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2img.name",
        default: "PDF to PDF/A",
      },
      description: {
        key: "tool.pdf2img.description",
        default:
          "Transform your PDF to PDF/A, the ISO-standardized version of PDF for long-term archiving. Your PDF will preserve formatting when accessed in the future.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Convert PDF to PDF/A",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Convert PDF files to PDF/A quickly and directly.",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "convert PDF", "to PDF/A"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to PDF/A Convert Successful",
      },
    },
  },
  {
    ID: 15,
    categoryID: 0,
    key: "0-4-pdf2excel",
    name: "PDFToExcel",
    path: "/pdf-to-excel",
    pathPreview: "/pdf-to-excel-pre",
    domain: "pdf2excel",
    icon: "icon_excel_to_pdf.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_excel.svg",
    beforeUploadInform:
      "Extract all your PDF tables to EXCEL spreadsheets automatically in just a few clicks. The best free PDF to Excel converter online.",

    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_excel.svg",
    color: "#FFB700",
    inputFileType: ["pdf"],
    outputFileType: ["xlsx"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2img.name",
        default: "PDF to Excel",
      },
      description: {
        key: "tool.pdf2img.description",
        default: "Pull data straight from PDFs into Excel spreadsheets in a few short seconds.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Convert PDF to Excel",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Convert PDF files to Excel quickly and directly.",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "convert PDF", "to Excel"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to Excel Convert Successful",
      },
    },
  },
  {
    ID: 16,
    categoryID: 0,
    key: "0-5-pdf2pptx",
    name: "PDFToPPTX",
    path: "/pdf-to-pptx",
    pathPreview: "/pdf-to-pptx-pre",
    domain: "pdf2pptx",
    icon: "icon_ppt_to_pdf.svg",
    beforeToolIcon: "icon_pdf_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_ppt_new.svg",
    beforeUploadInform:
      "Convert your PDFs to PowerPoint. Free and easy to use. No watermarks. Just converting PDF to PPT in seconds.",
    color: "#FFB700",
    inputFileType: ["pdf"],
    beforeUploadIcon: "/images/beforeUpload/show_select_pdf_pptx.svg",
    outputFileType: ["pptx"],
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2img.name",
        default: "PDF to PPTX",
      },
      description: {
        key: "tool.pdf2img.description",
        default: "Turn your PDF files into easy to edit PPT and PPTX slideshows.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Convert PDF to PPTX",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Convert PDF files to PPTX quickly and directly.",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "convert PDF", "to PPTX"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the PDF or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to PPTX Convert Successful",
      },
    },
  },
];
export const extraTool = [
  {
    key: "ex-0-moretool",
    name: "MoreTool",
    path: "/",
    domain: "",
    icon: "icon_more_tool.svg",
    context: {
      name: {
        key: "tool.moretool.name",
        default: "More tools",
      },
    },
  },
];
// 模板列表
export const templates = [
  {
    toolName: "Legal Contract",
    path: "/legal-contract",
    EventCategroy: "LegalContract",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "LegalContract.svg",
    fill: "#FC9A9A",
  },
  {
    toolName: "Chart Vector",
    path: "/chart-vector",
    EventCategroy: "ChartVector",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "ChartVector.svg",
    fill: "#FFCB00",
  },
  {
    toolName: "PDF/A Document",
    path: "/pdf-a-document",
    EventCategroy: "PDFADocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFADocument.svg",
    fill: "#82D8FF",
  },
  {
    toolName: "PDF/A Document",
    path: "/pdf-b-document",
    EventCategroy: "PDFBDocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFBDocument.svg",
    fill: "#A25DDC",
  },
];
export default { toolCategory, toolList, templates, extraTool };
// 工具ID
// 0  pdf2word
// 1  pdf2img
// 2  word2pdf
// 3  img2pdf
// 4  ppt2pdf
// 5  excel2pdf
// 6  editorpdf
// 7  pdfreader
// 8  compresspdf
// 9  splitpdf
// 10 mergepdf
// 11 rotatepdf
// 12 deletepdf
