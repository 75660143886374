import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const plRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //z-pdfu-do-Worda
  {
    path: "/z-pdfu-do-Worda",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/z-pdfu-do-Worda",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-do-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-do-jpg",
    page: <PDFToJpg />,
  },

  // słowo-do-pdf
  {
    path: "/słowo-do-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/słowo-do-pdf",
    page: <WordToPDF />,
  },

  // obraz-do-pdf
  {
    path: "/obraz-do-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/obraz-do-pdf",
    page: <ImageToPDF />,
  },

  // z-excela-do-pdf
  {
    path: "/z-excela-do-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/z-excela-do-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-do-pdf
  {
    path: "/ppt-do-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-do-pdf",
    page: <PPTToPDF />,
  },

  // skompresuj-pdf
  {
    path: "/skompresuj-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/skompresuj-pdf",
    page: <CompressPDF />,
  },

  // Połącz-pliki-PDF
  {
    path: "/Połącz-pliki-PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/Połącz-pliki-PDF",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/Połącz-pliki-PDF-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/Połącz-pliki-PDF-work-space",
    page: <MergePDFWorkSpace />,
  },

  // podziel-pdf
  {
    path: "/podziel-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/podziel-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/podziel-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/podziel-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // czytnik-PDF
  {
    path: "/czytnik-PDF",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/czytnik-PDF",
    page: <PDFReader />,
  },

  // usuń-pdf
  {
    path: "/usuń-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/usuń-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/usuń-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/usuń-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // obróć-pdf
  {
    path: "/obróć-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/obróć-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/obróć-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/obróć-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // edytor-pdf
  {
    path: "/edytor-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/edytor-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/edytor-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/edytor-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/skompresuj-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/skompresuj-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/Połącz-pliki-PDF-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/Połącz-pliki-PDF-pre",

    page: <MergePDF />,
  },

  {
    path: "/podziel-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/podziel-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/obróć-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/obróć-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/usuń-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/usuń-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/czytnik-PDF-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/czytnik-PDF-pre",

    page: <PDFReader />,
  },

  {
    path: "/z-pdfu-do-Worda-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/z-pdfu-do-Worda-pre",

    page: <PDFToWord />,
  },

  {
    path: "/słowo-do-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/słowo-do-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-do-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-do-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/z-excela-do-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/z-excela-do-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-do-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-do-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/obraz-do-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/obraz-do-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/png-do-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-do-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-do-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-do-png",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-i-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-i-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-i-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-i-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-i-plik",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-i-plik",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-i-plik-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-i-plik-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-i-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-i-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-i-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-i-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-i-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-i-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-i-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-i-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default plRoutJSON;
