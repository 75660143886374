// PDFTools
// 引入功能模块
// REACT
import React from "react";
// MUI
import Container from "@mui/material/Container";
// COMPONENT
import NavBar from "./component/NavBar";
import PDFToolList from "./component/PDFToolList";
import Loading from "./toolpage/component/Loading";
import { GlobalContext } from "../utils/globalContext";
import { Box } from "@mui/material";

//懒加载
const HomeStep = React.lazy(() => import("./component/HomeStep"));
const Footer = React.lazy(() => import("./component/Footer"));
// MAIN
export default function Home() {
  const { setFileIsEncrypt, setUploadFileModel } = React.useContext(GlobalContext);
  // 页面初始化
  React.useEffect(async () => {
    gotoRequestIfNeed();
    insertAd("#banner-ad-home1", "9397335020", "ca-pub-3275607498355456", "auto");
    // 获取先判断href
    // 判断是否存在语言路径
    // 如果不存在重定向
    // 当前的路径 /
    // const url = new URL(window.location.href)
    // window.location.href = url.pathname + "语言"
    // else {

    // }
    // 初始化存文件密码存储的数据
    await setFileIsEncrypt({
      isEncrypt: false,
      OpenPassword: null,
      authorityPassword: null,
    });
    // 清空pdfjs的记录
    window.localStorage.setItem("pdfjs.history", null);
    // 获取URL参数
    const searchURL = window.location.search;
    // 判断参数是否存在
    if (searchURL) {
      // URL携带参数
      // 初始化params
      const params = {};
      // 将`？`切换成`&`，并以`&`切割字符串，每个参数项为一个数组元素
      const search = searchURL.replace("?", "&").split("&");
      // 获取每个参数项的值
      for (let index = 1; index < search.length; index++) {
        // 获取参数项的key
        const key = search[index].split("=")[0];
        // 获取参数项的value
        const value = search[index].split("=")[1];
        // 填充到params中
        params[key] = value;
      }
      if (params?.scroll) {
        log(params.scroll);
        const targetElement = document.getElementsByClassName(params.scroll)[0];
        log(targetElement);
        if (targetElement) {
          log(targetElement);
          targetElement.scrollIntoView();
        }
      }
    }
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "#fff4f4",
      }}
    >
      {/* 顶部导航 */}
      <NavBar />
      <div
          style={{
            height: "auto",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
            marginBottom: "5px",
          }}
          id="banner-ad-home1"
      >
        Advertisement
      </div>
      {/* PDF工具列表 */}
      <Container>
        <PDFToolList />
      </Container>
      {/* 步骤 */}
      <React.Suspense fallback={<Loading />}>
        <HomeStep />
      </React.Suspense>
      {/* 页脚 */}
      <React.Suspense fallback={<Loading />}>
        <Footer />
      </React.Suspense>
    </Box>
  );
}
