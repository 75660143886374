function determineDomainModel() {
  const url = new URL(window.location.href);
  //   单域名
  if (
    url.hostname.split(".")[0] === "localhost" ||
    url.hostname.split(".")[0] === "127" ||
    url.hostname.split(".").length === 2
  ) {
    return false;
  } else {
    return true;
  }
}
export default determineDomainModel;
