import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const roRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-în-word
  {
    path: "/pdf-în-word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-în-word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-în-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-în-jpg",
    page: <PDFToJpg />,
  },

  // cuvânt-în-pdf
  {
    path: "/cuvânt-în-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/cuvânt-în-pdf",
    page: <WordToPDF />,
  },

  // imagine-în-pdf
  {
    path: "/imagine-în-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/imagine-în-pdf",
    page: <ImageToPDF />,
  },

  // Excel-în-pdf
  {
    path: "/Excel-în-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/Excel-în-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-în-pdf
  {
    path: "/ppt-în-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-în-pdf",
    page: <PPTToPDF />,
  },

  // comprimați-pdf
  {
    path: "/comprimați-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/comprimați-pdf",
    page: <CompressPDF />,
  },

  // combinare-pdf
  {
    path: "/combinare-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/combinare-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/combinare-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/combinare-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // divizează-pdf
  {
    path: "/divizează-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/:tag2/:tag3/divizează-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/divizează-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/divizează-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // cititor-PDF
  {
    path: "/cititor-PDF",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/cititor-PDF",
    page: <PDFReader />,
  },

  // Șterge-pdf
  {
    path: "/Șterge-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/Șterge-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/Șterge-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/Șterge-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // rotiți-pdf
  {
    path: "/rotiți-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/rotiți-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotiți-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotiți-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // editor-pdf
  {
    path: "/editor-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/editor-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/editor-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/editor-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/comprimați-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/comprimați-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/combinare-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/combinare-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/divizează-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/divizează-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/rotiți-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/rotiți-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/Șterge-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/Șterge-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/cititor-PDF-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/cititor-PDF-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-în-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-în-word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/cuvânt-în-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/cuvânt-în-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-în-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-în-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/Excel-în-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/Excel-în-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-în-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-în-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/imagine-în-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/imagine-în-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/png-în-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-în-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-în-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-în-png",

    page: <PDFToJpg />,
  },
  //新增功能

  {
    path: "/pdf-și-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-și-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-și-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-și-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-și-fișier",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-și-fișier",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-și-fișier-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-și-fișier-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-și-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-și-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-și-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-și-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-și-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-și-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-și-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-și-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default roRoutJSON;
