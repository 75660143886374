import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const arRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },
  {
    path: ":tag1/:tag2/",
    page: <Home />,
  },
  {
    path: ":tag1/:tag2/:tag3",
    page: <Home />,
  },
  //من-بي-دي-اف-إلى-وورد
  {
    path: "/من-بي-دي-اف-إلى-وورد",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/من-بي-دي-اف-إلى-وورد",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/:tag2/من-بي-دي-اف-إلى-وورد",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/:tag2/:tag3/من-بي-دي-اف-إلى-وورد",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/convert",
    page: <MiddlePageOfConvert />,
  },
  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/:tag6/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-إلى-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-إلى-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/pdf-إلى-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-إلى-jpg",
    page: <PDFToJpg />,
  },
  // Word-to-pdf
  {
    path: "/Word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/Word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/:tag2/Word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/Word-to-pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/صورة-إلى-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/صورة-إلى-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/صورة-إلى-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/صورة-إلى-pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/:tag2/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/excel-to-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-إلى-pdf
  {
    path: "/ppt-إلى-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-إلى-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/:tag2/ppt-إلى-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/ppt-إلى-pdf",
    page: <PPTToPDF />,
  },

  //  ضغط-pdf
  {
    path: "/ ضغط-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/ ضغط-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/:tag2/ ضغط-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/ ضغط-pdf",
    page: <CompressPDF />,
  },

  // دمج-pdf
  {
    path: "/دمج-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/دمج-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/:tag2/دمج-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/دمج-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/دمج-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/دمج-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/دمج-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/دمج-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // تقسيم-pdf
  {
    path: "/تقسيم-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/تقسيم-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/تقسيم-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/تقسيم-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/تقسيم-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/تقسيم-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/تقسيم-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/تقسيم-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // قارئ-pdf
  {
    path: "/قارئ-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/قارئ-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/:tag2/قارئ-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/:tag2/:tag3/قارئ-pdf",
    page: <PDFReader />,
  },
  // حذف-pdf
  {
    path: "/حذف-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/حذف-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/:tag2/حذف-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/حذف-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/حذف-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/حذف-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/حذف-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/حذف-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // تدوير-pdf
  {
    path: "/تدوير-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/تدوير-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/:tag2/تدوير-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/تدوير-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/تدوير-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/تدوير-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/تدوير-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/تدوير-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // محرر-pdf
  {
    path: "/محرر-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/محرر-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/محرر-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/:tag3/محرر-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },
  {
    path: "/:tag1/:tag2/subscribe",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/:tag2/:tag3/subscribe",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/محرر-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/محرر-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/محرر-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/:tag3/محرر-pdf-pre",
    page: <PDFEdit />,
  },
  {
    path: "/ضغط-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/ضغط-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/ضغط-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/ضغط-pdf-pre",
    page: <CompressPDF />,
  },
  {
    path: "/دمج-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/دمج-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/دمج-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/دمج-pdf-pre",
    page: <MergePDF />,
  },
  {
    path: "/تقسيم-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/تقسيم-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/تقسيم-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/تقسيم-pdf-pre",
    page: <SplitPDF />,
  },
  {
    path: "/تدوير-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/تدوير-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/تدوير-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/تدوير-pdf-pre",
    page: <RotatePDF />,
  },
  {
    path: "/حذف-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/حذف-pdf-pre",
    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/حذف-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/حذف-pdf-pre",
    page: <DeletePDF />,
  },
  {
    path: "/قارئ-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/قارئ-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/قارئ-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/:tag3/قارئ-pdf-pre",
    page: <PDFReader />,
  },
  {
    path: "/من-بي-دي-اف-إلى-وورد-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/من-بي-دي-اف-إلى-وورد-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/من-بي-دي-اف-إلى-وورد-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/:tag3/من-بي-دي-اف-إلى-وورد-pre",
    page: <PDFToWord />,
  },
  {
    path: "/Word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/Word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/Word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/Word-to-pdf-pre",
    page: <WordToPDF />,
  },
  {
    path: "/ppt-إلى-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-إلى-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/ppt-إلى-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/ppt-إلى-pdf-pre",
    page: <PPTToPDF />,
  },
  {
    path: "/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/excel-to-pdf-pre",
    page: <ExcelToPDF />,
  },
  {
    path: "/pdf-إلى-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-إلى-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/pdf-إلى-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-إلى-jpg-pre",
    page: <PDFToJpg />,
  },
  {
    path: "/صورة-إلى-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/صورة-إلى-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/صورة-إلى-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/صورة-إلى-pdf-pre",
    page: <ImageToPDF />,
  },

  {
    path: "/pdf-إلى-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-إلى-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png إلى pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png إلى pdf",

    page: <ImageToPDF />,
  },
  {
    path: "/pdf-و-pdf",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-و-pdf",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-و-pdf-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-و-pdf-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-وملف",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-وملف",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-وملف-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-وملف-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-و-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-و-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-و-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-و-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-و-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-و-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-و-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-و-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default arRoutJSON;
