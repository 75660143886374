import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const sqRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-në-fjalë
  {
    path: "/pdf-në-fjalë",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-në-fjalë",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-në-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-në-jpg",
    page: <PDFToJpg />,
  },

  // fjalë-në-pdf
  {
    path: "/fjalë-në-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/fjalë-në-pdf",
    page: <WordToPDF />,
  },

  // imazhi-në-pdf
  {
    path: "/imazhi-në-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/imazhi-në-pdf",
    page: <ImageToPDF />,
  },

  // eksel-në-pdf
  {
    path: "/eksel-në-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/eksel-në-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-në-pdf
  {
    path: "/ppt-në-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-në-pdf",
    page: <PPTToPDF />,
  },

  // kompres-pdf
  {
    path: "/kompres-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/kompres-pdf",
    page: <CompressPDF />,
  },

  // bashkoni-pdf
  {
    path: "/bashkoni-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/bashkoni-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/bashkoni-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/bashkoni-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // ndarje-pdf
  {
    path: "/ndarje-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/ndarje-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/ndarje-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/ndarje-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // lexues-pdf
  {
    path: "/lexues-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/lexues-pdf",
    page: <PDFReader />,
  },

  // fshi-pdf
  {
    path: "/fshi-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/fshi-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/fshi-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/fshi-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/rotate-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/rotate-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // redaktori-pdf
  {
    path: "/redaktori-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/redaktori-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/redaktori-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/redaktori-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/kompres-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/kompres-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/bashkoni-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/bashkoni-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/ndarje-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/ndarje-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/rotate-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/rotate-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/fshi-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/fshi-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/lexues-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/lexues-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-në-fjalë-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-në-fjalë-pre",

    page: <PDFToWord />,
  },

  {
    path: "/fjalë-në-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/fjalë-në-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-në-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-në-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/eksel-në-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/eksel-në-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-në-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-në-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/imazhi-në-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/imazhi-në-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-në-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-në-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-në-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-në-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-dhe-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-dhe-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-dhe-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-dhe-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-dhe-skedari",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-dhe-skedari",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-dhe-skedari-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-dhe-skedari-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-dhe-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-dhe-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-dhe-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-dhe-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-dhe-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-dhe-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-dhe-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-dhe-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default sqRoutJSON;
