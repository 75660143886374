import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const ukRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-в-слово
  {
    path: "/pdf-в-слово",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-в-слово",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-в-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-в-jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word-to-pdf",
    page: <WordToPDF />,
  },

  // зображення-в-pdf
  {
    path: "/зображення-в-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/зображення-в-pdf",
    page: <ImageToPDF />,
  },

  // Excel-в-pdf
  {
    path: "/Excel-в-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/Excel-в-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-в-pdf
  {
    path: "/ppt-в-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-в-pdf",
    page: <PPTToPDF />,
  },

  // стиснути-pdf
  {
    path: "/стиснути-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/стиснути-pdf",
    page: <CompressPDF />,
  },

  // об'єднати-pdf
  {
    path: "/об'єднати-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/об'єднати-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/об'єднати-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/об'єднати-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // розділити-pdf
  {
    path: "/розділити-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/розділити-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/розділити-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/розділити-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // читач-pdf
  {
    path: "/читач-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/читач-pdf",
    page: <PDFReader />,
  },

  // видалити-pdf
  {
    path: "/видалити-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/видалити-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/видалити-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/видалити-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // обернути-pdf
  {
    path: "/обернути-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/обернути-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/обернути-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/обернути-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // редактор-pdf
  {
    path: "/редактор-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/редактор-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/редактор-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/редактор-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/стиснути-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/стиснути-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/об'єднати-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/об'єднати-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/розділити-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/розділити-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/обернути-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/обернути-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/видалити-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/видалити-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/читач-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/читач-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-в-слово-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-в-слово-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-to-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-в-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-в-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/Excel-в-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/Excel-в-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-в-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-в-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/зображення-в-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/зображення-в-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-в-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-в-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-в-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-в-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-та-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-та-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-та-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-та-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-та-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-та-файл",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-та-файл-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-та-файл-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-і-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-і-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-і-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-і-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-і-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-і-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-і-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-і-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default ukRoutJSON;
