import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const nlRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //PDF-naar-Word
  {
    path: "/PDF-naar-Word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/PDF-naar-Word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-naar-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-naar-jpg",
    page: <PDFToJpg />,
  },

  // woord-naar-pdf
  {
    path: "/woord-naar-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/woord-naar-pdf",
    page: <WordToPDF />,
  },

  // afbeelding-naar-pdf
  {
    path: "/afbeelding-naar-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/afbeelding-naar-pdf",
    page: <ImageToPDF />,
  },

  // uitmunten-naar-pdf
  {
    path: "/uitmunten-naar-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/uitmunten-naar-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-naar-pdf
  {
    path: "/ppt-naar-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-naar-pdf",
    page: <PPTToPDF />,
  },

  // pdf-comprimeren
  {
    path: "/pdf-comprimeren",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/pdf-comprimeren",
    page: <CompressPDF />,
  },

  // samenvoegen-PDF
  {
    path: "/samenvoegen-PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/samenvoegen-PDF",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/samenvoegen-PDF-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/samenvoegen-PDF-work-space",
    page: <MergePDFWorkSpace />,
  },

  // pdf-splitsen
  {
    path: "/pdf-splitsen",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/pdf-splitsen",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/pdf-splitsen-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-splitsen-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // PDF-lezer
  {
    path: "/PDF-lezer",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/PDF-lezer",
    page: <PDFReader />,
  },

  // verwijder-pdf
  {
    path: "/verwijder-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/verwijder-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/verwijder-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/verwijder-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // draai-pdf
  {
    path: "/draai-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/draai-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/draai-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/draai-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf-editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-editor",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/pdf-editor-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-editor-pre",

    page: <PDFEdit />,
  },

  {
    path: "/pdf-comprimeren-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/pdf-comprimeren-pre",

    page: <CompressPDF />,
  },

  {
    path: "/samenvoegen-PDF-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/samenvoegen-PDF-pre",

    page: <MergePDF />,
  },

  {
    path: "/pdf-splitsen-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/pdf-splitsen-pre",

    page: <SplitPDF />,
  },

  {
    path: "/draai-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/draai-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/verwijder-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/verwijder-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/PDF-lezer-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/PDF-lezer-pre",

    page: <PDFReader />,
  },

  {
    path: "/PDF-naar-Word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/PDF-naar-Word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/woord-naar-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/woord-naar-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-naar-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-naar-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/uitmunten-naar-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/uitmunten-naar-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-naar-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-naar-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/afbeelding-naar-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/afbeelding-naar-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/png-naar-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-naar-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-naar-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-naar-png",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-en-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-en-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-en-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-en-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-en-bestand",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-en-bestand",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-en-bestand-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-en-bestand-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-en-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-en-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-en-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-en-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-en-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-en-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-en-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-en-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default nlRoutJSON;
