import * as Comlink from "comlinkjs";


async function encapsulateWorkerPassword(action,file, fileName,openPassword,authorityPassword) {
    initWebAssembly();
    const GoWorkerPassword = Comlink.proxy(new Worker("/worker_password.js"));
    const worker = await new GoWorkerPassword();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        async function callback(e) {
            var arrayBuffer = reader.result;
            let array = new Uint8Array(arrayBuffer);
            try {
                let tempFileObject =await worker.handleOpEnDeFun(action, array, fileName,openPassword,authorityPassword);
                console.log('%cworkerOfPDFCPUPassword.js line:16 tempFileObject', 'color: white; background-color: #007acc;', tempFileObject);
                resolve(tempFileObject)
            } catch (e) {
                console.log(e);
                resolve()
            }
        }
        reader.onload = callback;
        reader.readAsArrayBuffer(file);
    });
}






function initWebAssembly() {
    if (!WebAssembly.instantiateStreaming) {
        // polyfill
        WebAssembly.instantiateStreaming = async (resp, importObject) => {
            const source = await (await resp).arrayBuffer();
            return await WebAssembly.instantiate(source, importObject);
        };
    }
}

export { encapsulateWorkerPassword };
