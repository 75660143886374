import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const deRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/PDF zu Word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/PDF zu Word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/konvertieren",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/konvertieren",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/konvertieren",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/Ergebnis",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/Ergebnis",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/Ergebnis",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/Ergebnis",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-Arbeitsbereich bearbeiten",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-Arbeitsbereich bearbeiten",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf zu jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf zu jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/word zu pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word zu pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/bild zu pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/bild zu pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel zu pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel zu pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt zu pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt zu pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/pdf komprimieren",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/pdf komprimieren",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/PDF zusammenführen",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/PDF zusammenführen",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge pdf arbeitsbereich",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge pdf arbeitsbereich",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf Arbeitsbereich",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf Arbeitsbereich",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/PDF Reader",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/PDF Reader",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/pdf löschen",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/pdf löschen",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/pdf-arbeitsbereich löschen",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-arbeitsbereich löschen",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/pdf drehen",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/pdf drehen",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf-Editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-Editor",
    page: <PDFEdit />,
  },

  //abonnieren
  {
    path: "/abonnieren",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/abonnieren",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/profil",
    page: <Profile />,
    path1: "/profil/Passwort auf altes Passwort zurücksetzen",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profil",
    page: <Profile />,
    path1: "/:tag1/profil/Passwort auf altes Passwort zurücksetzen",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profil",
    page: <Profile />,
    path1: "/:tag1/:tag2/profil/Passwort auf altes Passwort zurücksetzen",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profil",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profil/Passwort auf altes Passwort zurücksetzen",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profil/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },

  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/PDF-Editor-Vorschau-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/PDF-Editor-Vorschau-pre",

    page: <PDFEdit />,
  },

  {
    path: "/PDF-komprimieren-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/PDF-komprimieren-pre",

    page: <CompressPDF />,
  },

  {
    path: "/PDF-zusammenführen-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/PDF-zusammenführen-pre",

    page: <MergePDF />,
  },

  {
    path: "/PDF-teilen-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/PDF-teilen-pre",

    page: <SplitPDF />,
  },

  {
    path: "/pdf-löschen-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/pdf-löschen-pre",
    page: <DeletePDF />,
  },

  {
    path: "/PDF-Reader-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/PDF-Reader-pre",

    page: <PDFReader />,
  },

  {
    path: "/PDF-zu-Word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/PDF-zu-Word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/Word-zu-PDF-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/Word-zu-PDF-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-zu-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-zu-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/Excel-zu-PDF-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/Excel-zu-PDF-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/PDF-zu-JPG-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/PDF-zu-JPG-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/von-Bild-zu-PDF-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/von-Bild-zu-PDF-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-zu-png-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-zu-png-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/png-zu-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-zu-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/PDF-drehen-pre",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/PDF-drehen-pre",
    page: <RotatePDF />,
  },
  {
    path: "/PDF-Editor-pre",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/PDF-Editor-pre",
    page: <PDFEdit />,
  },

  //新增功能

  {
    path: "/pdf-und-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-und-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-und-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-und-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-und-datei",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-und-datei",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-und-datei-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-und-datei-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-und-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-und-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-und-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-und-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-und-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-und-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-und-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-und-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default deRoutJSON;
