import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const thRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/pdf เป็นคำ",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf เป็นคำ",
    page: <PDFToWord />,
  },

  //   แปลง
  {
    path: "/:tag1/แปลง",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/แปลง",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/แปลง",
    page: <MiddlePageOfConvert />,
  },

  //   ผลลัพธ์
  {
    path: "/:tag1/ผลลัพธ์",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/ผลลัพธ์",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/ผลลัพธ์",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/ผลลัพธ์",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf แก้ไขพื้นที่ทำงาน",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf แก้ไขพื้นที่ทำงาน",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf เป็น jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf เป็น jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/คำเป็น pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/คำเป็น pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/รูปภาพเป็น pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/รูปภาพเป็น pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel เป็น pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel เป็น pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt เป็น pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt เป็น pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/บีบอัดไฟล์ PDF",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/บีบอัดไฟล์ PDF",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/รวมไฟล์ PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/รวมไฟล์ PDF",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge พื้นที่ทำงาน pdf",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge พื้นที่ทำงาน pdf",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/แยกไฟล์ PDF",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/แยกไฟล์ PDF",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf พื้นที่ทำงาน",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf พื้นที่ทำงาน",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/โปรแกรมอ่าน pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/โปรแกรมอ่าน pdf",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/ลบไฟล์ PDF",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/ลบไฟล์ PDF",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/ลบพื้นที่ทำงาน pdf",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/ลบพื้นที่ทำงาน pdf",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/หมุนไฟล์ PDF",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/หมุนไฟล์ PDF",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/หมุนพื้นที่ทำงาน PDF",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/หมุนพื้นที่ทำงาน PDF",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/โปรแกรมแก้ไข pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/โปรแกรมแก้ไข pdf",
    page: <PDFEdit />,
  },

  //ติดตาม
  {
    path: "/ติดตาม",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/ติดตาม",
    page: <SubscribePage />,
  },

  // ประวัติโดยย่อ
  {
    path: "/ประวัติโดยย่อ",
    page: <Profile />,
    path1: "/ประวัติโดยย่อ/รีเซ็ตรหัสผ่านด้วยรหัสผ่านเก่า",
    page1: <ResetPasswordByOldPassword />,
    path2: "/ประวัติโดยย่อ/แผน",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/ประวัติโดยย่อ",
    page: <Profile />,
    path1: "/:tag1/ประวัติโดยย่อ/รีเซ็ตรหัสผ่านด้วยรหัสผ่านเก่า",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/ประวัติโดยย่อ/แผน",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/ประวัติโดยย่อ",
    page: <Profile />,
    path1: "/:tag1/:tag2/ประวัติโดยย่อ/รีเซ็ตรหัสผ่านด้วยรหัสผ่านเก่า",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/ประวัติโดยย่อ/แผน",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/ประวัติโดยย่อ",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/ประวัติโดยย่อ/รีเซ็ตรหัสผ่านด้วยรหัสผ่านเก่า",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/ประวัติโดยย่อ/แผน",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/โปรแกรมแก้ไข-PDF-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/โปรแกรมแก้ไข-PDF-pre",

    page: <PDFEdit />,
  },

  {
    path: "/บีบอัดไฟล์-PDF-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/บีบอัดไฟล์-PDF-pre",

    page: <CompressPDF />,
  },

  {
    path: "/รวมไฟล์ PDF-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/รวมไฟล์ PDF-pre",

    page: <MergePDF />,
  },

  {
    path: "/แยกไฟล์-PDF-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/แยกไฟล์-PDF-pre",

    page: <SplitPDF />,
  },

  {
    path: "/หมุนไฟล์-PDF-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/หมุนไฟล์-PDF-pre",

    page: <RotatePDF />,
  },

  {
    path: "/ลบไฟล์-PDF-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/ลบไฟล์-PDF-pre",
    page: <DeletePDF />,
  },

  {
    path: "/โปรแกรมอ่านไฟล์-PDF-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/โปรแกรมอ่านไฟล์-PDF-pre",

    page: <PDFReader />,
  },

  {
    path: "/PDF-เป็น-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/PDF-เป็น-word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-เป็น-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-เป็น-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-เป็น-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-เป็น-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/เก่งเป็น-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/เก่งเป็น-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/PDF-และ-jpgs-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/PDF-และ-jpgs-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/รูปภาพเป็น-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/รูปภาพเป็น-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: "/png-เป็น-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-เป็น-pdf",

    page: <ImageToPDF />,
  },
  {
    path: "/pdf-เป็น-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-เป็น-png",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-และ-pdf-ก",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-และ-pdf-ก",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-และ-pdf-ก-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-และ-pdf-ก-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-และไฟล์",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-และไฟล์",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-และไฟล์-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-และไฟล์-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-และ-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-และ-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-และ-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-และ-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-และ-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-และ-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-และ-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-และ-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default thRoutJSON;
