// 顶部导航
// 引入功能模块
// REACT
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// MUI
import {
  AppBar,
  Box,
  Button,
  Container,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
// MUI ICON
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// COMPONENT
// 用户头像组件
import UserAvatar from "./UserAvatar";
// 用户登录注册抽屉页面
import MyDrawer from "./User/MyDrawer";
// 工具菜单
import MenuList from "./MenuList";
import { Trans } from "react-i18next/icu.macro";
// GLOBAL全局状态
import { GlobalContext } from "../../utils/globalContext";
import { useMedia } from "react-use";
import Link from "../component/Link";
import determineDomainModel from "../../utils/determineDomainModel";
import { navOptionsList, toolLists } from "../../data";
import { Helmet } from "react-helmet";
// MAIN
export default function NavBar() {
  const { i18n } = useTranslation();
  // 从全局状态中获取userName用户名,userToken用户登录凭证
  const { userName, userToken, userSubState, setLanguage, getLanguage, language } =
    React.useContext(GlobalContext);
  // 下拉菜单锚点
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  // 下拉菜单打开状态,锚点存在时为true,反之为false
  const menuOpenState = Boolean(menuAnchor);
  const [open, setOpen] = React.useState(false);
  //媒体查询
  const isWide = useMedia("(min-width: 1050px)");
  //设置抽屉是否打开
  const handleClick = (event) => {
    const index = event.currentTarget.attributes.value.value;
    collapseList[index].open = !collapseList[index].open;
    setCollapseList([...collapseList]);
  };
  //设置抽屉是否打开
  const handleClicks = () => {
    setOpen(!open);
  };
  // useEffect(async () => {
  //   const languageArray = [
  //     "af",
  //     "af-ZA",
  //     'ar-001',
  //     "be",
  //     "de",
  //     "en",
  //     'fil-PH',
  //     "fr",
  //     "ko",
  //     "lt",
  //     "ms",
  //     "nl",
  //     "pl",
  //     "pl-PL",
  //     "pt",
  //     "pt-BR",
  //     "ro",
  //     "ru",
  //     "sk",
  //     "sl",
  //     "sq",
  //     "sr",
  //     "sv",
  //     "ta",
  //     "th",
  //     "tr",
  //     "uk",
  //     "vi",
  //     "zh",
  //     "zh-Hans-HK",
  //     "zh-Hant-TW",
  //     "ja-JP"]
  //   const url = new URL(window.location.href)
  //   if (languageArray.indexOf(url.pathname.split("/")[1]) === -1) {
  //     url.pathname = `/${language}${url.pathname}`
  //     window.open(url.href, "_self")
  //   } else {
  //     await setLanguage(url.pathname.split("/")[1]);
  //   }
  // }, [])

  const [collapseList, setCollapseList] = React.useState(toolLists);
  const { t } = useTranslation();
  let [canonical, setCanonical] = React.useState(window.location.href);
  useEffect(() => {
    // 删除url channel
    const url = new URL(window.location.href);
    if (url.pathname.match(/^\/f\/[^\/]+/)) {
      let newURL = url.pathname.replaceAll(url.pathname.match(/^\/f\/[^\/]+/)[0], "");
      url.pathname = newURL;
    } else {
      setCanonical(url.href);
    }
    // 删除参数
    if (url.href.indexOf("?") !== -1) {
      setCanonical(url.href.slice(0, url.href.indexOf("?")));
    } else {
      setCanonical(url.href);
    }
  }, []);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={decodeURI(canonical)} />
      </Helmet>
      <AppBar
        sx={{
          top: 0,
          left: 0,
          maxWidth: "100vw",
          width: "100%",
          height: "72px",
          padding: "0px",
          backgroundColor: "#fff",
          boxShadow: "0",
          borderBottom: "1px solid #eeeeee",
        }}
      >
        <Container maxWidth={false} sx={{ height: "100%" }}>
          <Grid container sx={{ height: "100%", flexWrap: "nowrap" }}>
            {/* 顶部导航选项 */}
            <Grid item>
              <Grid
                container
                sx={{
                  height: "72px",
                  overflow: "hidden",
                  flexWrap: "nowrap",
                }}
              >
                {/* Logo */}
                <Box className="logoImg" sx={{ height: "100%", padding: "18px 0" }}>
                  <Link
                    href="/"
                    position={determineDomainModel() ? "nav_to_home" : ""}
                    onClick={() => {
                      sendConstructEventByEventParams("navbar", {
                        click: "logo",
                      });
                    }}
                  >
                    {/* <img
                      style={{
                        height: "100%",
                        display: "block",
                      }}
                      src="/images/logo.svg"
                      alt="8vs logo"
                    /> */}
                    <img
                      src="/8vs-pdf.webflow/home/images/PDF-logo-1.png"
                      loading="lazy"
                      width="200"
                      sizes="(max-width: 479px) 72vw, (max-width: 991px) 200px, 16vw"
                      srcSet="/8vs-pdf.webflow/home/images/PDF-logo-1.png 500w, /8vs-pdf.webflow/home/images/PDF-logo-1.png 800w"
                      alt="8vs logo"
                    />
                  </Link>
                </Box>
                {/* nav */}
                {isWide && (
                  <Box
                    sx={{
                      paddingLeft: "48px",
                      lineHeight: "72px",
                      "&>a": {
                        textDecorationLine: "none",
                      },
                    }}
                  >
                    <Button
                      disableRipple
                      sx={{
                        padding: "0px",
                        ":hover": { backgroundColor: "#FFF" },
                        color: "#535768",
                      }}
                      endIcon={menuOpenState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      onClick={(event) => {
                        setMenuAnchor(event.currentTarget);
                        sendConstructEventByEventParams("navbar", {
                          move: "drop_menu",
                          menu_state: "open",
                        });
                      }}
                    >
                      <Typography variant="body5">
                        <Trans i18nKey="All Tools">All Tools</Trans>
                      </Typography>
                    </Button>
                    {/* 下拉菜单 */}
                    <Menu
                      sx={{
                        "&>div:nth-of-type(3)": {
                          maxWidth: "100%",
                          width: "100%",
                          top: "72px !important",
                          left: "0 !important",
                          borderRadius: "0",
                          boxShadow: "1px 2px 2px rgb(0 0 0 / 12%)",
                        },
                      }}
                      open={menuOpenState}
                      anchorEl={menuAnchor}
                      onClose={() => {
                        setMenuAnchor(null);
                        sendConstructEventByEventParams("navbar", {
                          click: "drop_menu",
                          menu_state: " close",
                        });
                      }}
                    >
                      <MenuList />
                    </Menu>
                    {navOptionsList.map((navOption, index) => (
                      <Link
                        key={navOption.key}
                        href={navOption.path}
                        subDomain={navOption.domain}
                        position={determineDomainModel() ? "nav_to_function" : ""}
                      >
                        <Button
                          disableRipple
                          sx={{
                            marginLeft: "32px",
                            padding: "0px",
                            ":hover": { backgroundColor: "#FFF" },
                            color: "#535768",
                          }}
                          onClick={() => {
                            sendConstructEventByEventParams("navbar", {
                              click: "navigation",
                              navigate: navOption.name,
                            });
                          }}
                        >
                          <Typography variant="body5">
                            <Trans i18nKey={navOption.context.name.default}>
                              {navOption.context.name.default}
                            </Trans>
                          </Typography>
                        </Button>
                      </Link>
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Grid
                container
                alignItems="center"
                direction="row-reverse"
                sx={{
                  height: "100%",
                  overflow: "hidden",
                  flexWrap: "nowrap",
                }}
              >
                {userName && userToken ? (
                  <Box sx={{ lineHeight: "72px" }}>
                    <UserAvatar
                      sx={{
                        margin: { sm: "0 32px", xs: "0 16px" },
                        marginRight: { sm: 0, xs: "16px" },
                      }}
                      type="menu"
                      username={userName}
                    />
                  </Box>
                ) : (
                  <React.Fragment>
                    <MyDrawer anchor={"right"} startPage={"onTrial"}>
                      {isWide && (
                        <Button
                          sx={{
                            height: "40px",
                            padding: { xs: "12px 24px", md: "8px 25px" },
                            borderRadius: "20px",
                            ":hover": { backgroundColor: "#EF5350" },
                          }}
                          variant="contained"
                        >
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: 500, whiteSpace: "nowrap" }}
                          >
                            <Trans i18nKey="Sign Up">Sign Up</Trans>
                          </Typography>
                        </Button>
                      )}
                    </MyDrawer>
                    <div
                      style={{
                        width: "15px",
                      }}
                    ></div>
                    <MyDrawer anchor={"right"} from="navbar" startPage={"login"}>
                      <Typography
                        className="loginButton"
                        variant="caption"
                        sx={{
                          margin: "auto",
                          color: "#333",
                          fontWeight: 500,
                          cursor: "pointer",
                          userSelect: "none",
                          marginRight: "5px",
                        }}
                        onClick={() => {
                          sendConstructEventByEventParams("navbar", {
                            click: "login",
                          });
                        }}
                      >
                        <Trans i18nKey="Login">Login</Trans>
                      </Typography>
                    </MyDrawer>
                  </React.Fragment>
                )}

                <Box sx={{ height: "100%", lineHeight: "72px" }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#333",
                      fontWeight: 500,
                      cursor: "pointer",
                      userSelect: "none",
                      marginRight: "32px",
                      "&>a": { textDecorationLine: "none", color: "#333" },
                    }}
                  >
                    {/* {userSubState && !userSubState.vip && (
                      <Link
                        onClick={() => {
                          sendConstructEventByEventParams("navbar", {
                            click: "pricing",
                          });
                        }}
                        href={t(`/subscribe`)}
                        position={determineDomainModel() ? "nav_to_subscribe" : ""}
                      >
                        <Trans i18nKey="Pricing">Pricing</Trans>
                      </Link>
                    )} */}
                  </Typography>
                </Box>
                <Grid item xs />
              </Grid>
            </Grid>
            {!isWide && (
              <Grid item onClick={handleClicks} sx={{ padding: "24px 12px", color: "#000" }}>
                {open ? (
                  <img
                    onClick={() => {
                      sendConstructEventByEventParams("navbar", {
                        click: "collapse_menu",
                        menu_state: "close",
                      });
                    }}
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    src="/images/icon/icon_delete.png"
                  />
                ) : (
                  <DensityMediumIcon
                    onClick={() => {
                      sendConstructEventByEventParams("navbar", {
                        click: "collapse_menu",
                        menu_state: "open",
                      });
                    }}
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Container>
        {/* 下拉的内容区域 */}
        {!isWide && (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              backgroundColor: "#000",
              color: "#333",
              "div:last-child": {
                border: "0",
              },
            }}
          >
            {collapseList.map((category, index) => (
              <List
                component="div"
                disablePadding
                key={category.category}
                onClick={() => {
                  sendConstructEventByEventParams("navbar_collapse_menu", {
                    click: "extended_panel",
                    menu_state: category.open ? "open" : "close",
                    category: category.category,
                  });
                }}
                sx={{
                  backgroundColor: "#ffffff",
                  zIndex: "5000000",
                }}
              >
                <ListItemButton value={index} onClick={handleClick} sx={{ paddingLeft: "20px" }}>
                  {/* 遍历出每一个名 */}
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "Quicksand-Bold, Quicksand",
                      variant: "body5",
                    }}
                    primary={<Trans i18nKey={category.category}>{category.category}</Trans>}
                  />
                  {category.open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={category.open}
                  timeout="auto"
                  unmountOnExit
                  sx={{ paddingLeft: "16px" }}
                >
                  {/* 遍历出所有的 图片 和 文字 */}
                  {collapseList[index].toolList.map((tool, id) => (
                    <Link
                      key={tool.key}
                      style={{
                        textDecoration: "none",
                        color: "black",
                        display:
                          /Mobi|Android|iPhone/i.test(navigator.userAgent) === true &&
                          tool.ID === 12
                            ? "none"
                            : "block",
                      }}
                      href={tool.path}
                      subDomain={tool.domain}
                      position={determineDomainModel() ? "nav_to_function" : ""}
                    >
                      <ListItemButton
                        onClick={(e) => {
                          e.stopPropagation();
                          sendConstructEventByEventParams("navbar_collapse_menu", {
                            click: "navigation",
                            navigate: tool.name,
                          });
                        }}
                        sx={{
                          pl: 4,
                          "&>a": {
                            textDecorationLine: "none",
                            color: "#000",
                          },
                        }}
                      >
                        <img
                          style={{
                            width: "32px",
                            height: "32px",
                            marginRight: "15px",
                          }}
                          src={"/images/icon/" + tool.icon}
                        />
                        <Typography variant="caption">
                          <Trans i18nKey={tool.context.name.default}>
                            {tool.context.name.default}
                          </Trans>
                        </Typography>
                      </ListItemButton>
                    </Link>
                  ))}
                </Collapse>
              </List>
            ))}
          </Collapse>
        )}
      </AppBar>
      <Box sx={{ height: "72px" }} />
    </>
  );
}
