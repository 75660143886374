import FileSaver from "file-saver";
import { navigate } from './navigate'
import { toolPageInfoKey } from '../data/toolPageInformation';
import { encapsulateWorkerPassword } from './workerOfPDFCPUPassword';

let originFileSize;
// 压缩函数
async function compressPDFFun({ file, setFileSize,
  setOriginalSize, originFile }, setIsShowSnackbarLoading) {

  // 对文件进行压缩
  let result = await encapsulateWorkerPassword('compress', file);
  //如果压缩失败，将原始的文件赋值给压缩的结果
  if (result == null) {
    result = file;
  }
  // 压缩成果
  if (result) {
    // 压缩后的文件大于初始的文件
    if (result.size > file.size) {
      if (setFileSize != undefined) {
        originFileSize = originFile.size;
        setOriginalSize(originFile.size);
        setFileSize(originFile.size);
        setIsShowSnackbarLoading(false)
      }
      return file;
    } else {
      if (setFileSize != undefined) {
        originFileSize = originFile.size;
        setOriginalSize(originFile.size);
        setFileSize(result.size);
        setIsShowSnackbarLoading(false)

      }
      return result;
    }
  }
}

window.receiveMessage = function ({ setCanSave, setIsShowSnackbarLoading, setOriginalSize,
  setFileSize, file, setActiveChangeData }, handleFun) {
  window.onmessage = async function (event) {

    // 编辑界面添加压缩和取消压缩
    if (event.data == 'modifySize') {
      setActiveChangeData(true)
    }
    if (event.data == 'cancelModifySize') {
      setActiveChangeData(false)
    }

    if (event.data == 'canSave') {
      setCanSave(true)
    }
    //结果页面压缩
    if (event.data == 'confirmResultCompress') {
      setIsShowSnackbarLoading(true)
      logi('结果页面压缩')
      compressPDFFun({ file, setFileSize, setOriginalSize, originFile: file }, setIsShowSnackbarLoading);
    }

    //结果页面取消压缩
    if (event.data == 'cancelResultCompress') {
      setOriginalSize(null);
      setFileSize(originFileSize);
    }

    if (event.data == 'ResultRedBtnToEditor') {
      logi("red button to editor");
      handleFun(true);
    }


  }
}


