// REACT
import React from "react";
// MUI
import {
  Avatar,
  Badge,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  Box,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
// MUI ICON
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { GlobalContext } from "../../utils/globalContext";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import Link from "./Link";
// 根据用户名生成头像颜色
const stringToColor = (string) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};
// 截取用户名前两位
const stringAvatar = (name, style = {}) => {
  return {
    // 修改头像组件样式
    sx: { backgroundColor: stringToColor(name), ...style },
    // 填充截取后的用户名
    children: `${name.split("")[0][0]}${name.split("")[1][0]}`,
  };
};

// 普通头像 props接收用户名username及组建样式sx
function NormalUserAvatar(props) {
  return <Avatar {...stringAvatar(props.username, props.sx)} />;
}
// 菜单头像 props接收用户名username及组建样式sx
function MenuUserAvatar(props) {
  //引入全局状态修改方法
  const { setUserToken, setUserName, setUserSubState, userSubState, language } =
    React.useContext(GlobalContext);
  // 下拉菜单列表
  const menuList = [
    {
      svg: [<PersonOutlinedIcon />],
      option: "Account",
      onClick: () => { },
      isNew: false,
    },
    {
      svg: [<LogoutIcon />],
      option: "Log out",
      onClick: () => {
        setUserName(null);
        setUserToken(null);
        setUserSubState(null);
        sendConstructEventByEventParams("navbar_avatar", {
          click: "logout",
        });
      },
      isNew: false,
    },
  ];
  //用户抽屉
  const [state, setState] = React.useState({
    right: false,
  });
  const myInformation = (anchor, open) => (event) => {
    sendConstructEventByEventParams("navbar", {
      click: "avatar",
      menu_state: open ? "open" : "close",
    });
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    console.log("%cNavBar.js line:79 object", "color: #007acc;", { ...state });
  };
  const closeInformationDrawer = () => {
    sendConstructEventByEventParams("navbar", {
      click: "avatar",
      menu_state: "close",
    });
    setState(false);
  };
  const { t } = useTranslation();
  return (
    // 角标
    <Badge>
      {/* 用户头像 */}
      <Avatar
        {...stringAvatar(props.username, props.sx)}
        onClick={myInformation("right", true)}
      />
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={myInformation("right", false)}
      >
        <Box sx={{ width: "500px" }}></Box>
        {/* 下拉菜单 */}
        <Box
          sx={{
            width: "400px",
            margin: "0 auto",
          }}
        >
          <Grid
            sx={{
              position: "absolute",
              left: "90%",
              top: "30px",
              width: "50px",
            }}
            container
          >
            <CloseSharpIcon
              sx={{
                color: "rgb(0,0,0)",
                fontSize: "50px",
              }}
              onClick={closeInformationDrawer}
            ></CloseSharpIcon>
          </Grid>
          <Box
            sx={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "45%",
              transform: "translateY(-50%)",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: "18px",
              }}
            >
              {/* 用户头像 */}
              <NormalUserAvatar
                username={props.username}
                sx={{ width: "90px", height: "90px", fontSize: "50px" }}
              />
            </Grid>
            <Grid
              justifyContent="center"
              container
              alignItems="center"
              sx={{ padding: "18px" }}
            >
              {/* 用户名 */}
              <Typography>{props.username}</Typography>
            </Grid>
            {/* 菜单列表 */}
            <List sx={{ "&>a": { textDecoration: "none", color: "#000" } }}>
              {menuList.map((option, index) => (
                <React.Fragment key={index}>
                  {option.option == "Account" ? (
                    <Link
                      onClick={() => {
                        sendConstructEventByEventParams("navbar_avatar", {
                          click: "account",
                        });
                      }}
                      href={t(`/profile`)}
                      position="to_profile"
                    >
                      <ListItemButton sx={{ marginTop: "20px" }}>
                        <ListItemIcon>{option.svg[0]}</ListItemIcon>
                        <ListItemText primary={option.option} />
                        {option.isNew && (
                          <Typography
                            sx={{
                              backgroundColor: "#000",
                              color: "#fff",
                              fontSize: "12px",
                              padding: "0 8px",
                              borderRadius: "20px",
                            }}
                          >
                            New
                          </Typography>
                        )}
                        <ArrowForwardIosIcon fontSize="samll" />
                      </ListItemButton>
                    </Link>
                  ) : (
                    <ListItemButton
                      onClick={option.onClick}
                      sx={{ marginTop: "20px" }}
                    >
                      <ListItemIcon>{option.svg[0]}</ListItemIcon>
                      <ListItemText primary={option.option} />
                      {option.isNew && (
                        <Typography
                          sx={{
                            backgroundColor: "#000",
                            color: "#fff",
                            fontSize: "12px",
                            padding: "0 8px",
                            borderRadius: "20px",
                          }}
                        >
                          New
                        </Typography>
                      )}
                      <ArrowForwardIosIcon fontSize="samll" />
                    </ListItemButton>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Box>
      </Drawer>
    </Badge>
  );
}
// 离线头像
function OutLine(props = { sx: { width: "40px", height: "40px" } }) {
  return (
    <IconButton {...props}>
      <PersonOutlinedIcon />
    </IconButton>
  );
}
// MAIN
export default function UserAvatar(props) {
  // 用户头像种类
  const userAvatarType = ["normal", "menu", "outline"];
  // 返回对应种类的头像
  if (props.type === userAvatarType[0]) {
    return <NormalUserAvatar {...props} />;
  } else if (props.type === userAvatarType[1]) {
    return <MenuUserAvatar {...props} />;
  } else if (props.type === userAvatarType[2]) {
    return <OutLine {...props} />;
  }
}
