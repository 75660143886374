import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const zhRoutJSON = [
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //PDF转Word
  {
    path: "/PDF转Word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/PDF转Word",
    page: <PDFToWord />,
  },

  {
    path: "/PDF转Word/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/PDF转Word/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/PDF转Word/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/PDF转Word/转换/成功",
    page: <Result />,
  },

  {
    path: "/PDF编辑器/PDF编辑器工作区",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/PDF编辑器/PDF编辑器工作区",
    page: <PDFEditWorkSpace />,
  },

  // PDF转JPG
  {
    path: "/PDF转JPG",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/PDF转JPG",
    page: <PDFToJpg />,
  },

  {
    path: "/PDF转JPG/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/PDF转JPG/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/PDF转JPG/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/PDF转JPG/转换/成功",
    page: <Result />,
  },

  // Word转PDF
  {
    path: "/Word转PDF",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/Word转PDF",
    page: <WordToPDF />,
  },

  {
    path: "/Word转PDF/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/Word转PDF/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/Word转PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/Word转PDF/转换/成功",
    page: <Result />,
  },

  // Image-to-pdf
  {
    path: "/图片转PDF",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/图片转PDF",
    page: <ImageToPDF />,
  },

  {
    path: "/图片转PDF/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/图片转PDF/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/图片转PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/图片转PDF/转换/成功",
    page: <Result />,
  },

  // Excel转PDF
  {
    path: "/Excel转PDF",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/Excel转PDF",
    page: <ExcelToPDF />,
  },

  {
    path: "/Excel转PDF/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/Excel转PDF/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/Excel转PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/Excel转PDF/转换/成功",
    page: <Result />,
  },

  // PPT转PDF
  {
    path: "/PPT转PDF",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/PPT转PDF",
    page: <PPTToPDF />,
  },

  {
    path: "/PPT转PDF/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/PPT转PDF/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/PPT转PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/PPT转PDF/转换/成功",
    page: <Result />,
  },

  // 压缩PDF
  {
    path: "/压缩PDF",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/压缩PDF",
    page: <CompressPDF />,
  },

  {
    path: "/压缩PDF/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/压缩PDF/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/压缩PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/压缩PDF/转换/成功",
    page: <Result />,
  },

  // merge-pdf
  {
    path: "/合并PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/合并PDF",
    page: <MergePDF />,
  },

  {
    path: "/合并PDF/合并PDF工作空间",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/合并PDF/合并PDF工作空间",
    page: <MergePDFWorkSpace />,
  },

  {
    path: "/合并PDF/合并PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/合并PDF/合并PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/合并PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/合并PDF/转换/成功",
    page: <Result />,
  },

  // 拆分PDF
  {
    path: "/拆分PDF",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/拆分PDF",
    page: <SplitPDF />,
  },

  {
    path: "/拆分PDF/拆分PDF工作空间",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/拆分PDF/拆分PDF工作空间",
    page: <SplitPDFWorkSpace />,
  },

  {
    path: "/拆分PDF/拆分PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/拆分PDF/拆分PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/拆分PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/拆分PDF/转换/成功",
    page: <Result />,
  },

  // PDF阅读器
  {
    path: "/PDF阅读器",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/PDF阅读器",
    page: <PDFReader />,
  },

  {
    path: "/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/转换/成功",
    page: <Result />,
  },

  // 删除PDF
  {
    path: "/删除PDF",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/删除PDF",
    page: <DeletePDF />,
  },

  {
    path: "/删除PDF/删除PDF工作空间",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/删除PDF/删除PDF工作空间",
    page: <DeletePDFWorkSpace />,
  },

  {
    path: "/删除PDF/删除PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/删除PDF/删除PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/删除PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/删除PDF/转换/成功",
    page: <Result />,
  },

  // 旋转PDF
  {
    path: "/旋转PDF",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/旋转PDF",
    page: <RotatePDF />,
  },

  {
    path: "/旋转PDF/旋转PDF工作空间",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/旋转PDF/旋转PDF工作空间",
    page: <RotatePDFWorkSpace />,
  },

  {
    path: "/旋转PDF/旋转PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/旋转PDF/旋转PDF工作空间/转换",
    page: <MiddlePageOfConvert />,
  },

  {
    path: "/旋转PDF/转换/成功",
    page: <Result />,
  },
  {
    path: "/:tag1/旋转PDF/转换/成功",
    page: <Result />,
  },

  // PDF编辑器
  {
    path: "/PDF编辑器",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/PDF编辑器",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/PDF编辑器",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/:tag3/PDF编辑器",
    page: <PDFEdit />,
  },
  {
    path: "/PDF编辑器/PDF 编辑器工作区",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/PDF编辑器/PDF 编辑器工作区",
    page: <PDFEditWorkSpace />,
  },

  //subscribe
  {
    path: "/订阅",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/订阅",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/用户资料",
    page: <Profile />,
    path1: "/用户资料/修改密码",
    page1: <ResetPasswordByOldPassword />,
    path2: "/用户资料/订阅计划",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/用户资料",
    page: <Profile />,
    path1: "/:tag1/用户资料/修改密码",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/用户资料/订阅计划",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/用户资料",
    page: <Profile />,
    path1: "/:tag1/:tag2/用户资料/修改密码",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/用户资料/订阅计划",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/用户资料",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/用户资料/修改密码",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/用户资料/订阅计划",
    page2: <ProfileContent />,
  },
  // {
  //   path: "/profile/修改密码",
  //   page: <ResetPasswordByOldPassword />,
  // },
  // {
  //   path: "/:tag1/profile/修改密码",
  //   page: <ResetPasswordByOldPassword />,
  // },
  // {
  //   path: "/:tag1/:tag2/profile/修改密码",
  //   page: <ResetPasswordByOldPassword />,
  // },
  // {
  //   path: "/:tag1/:tag2/:tag3/profile/修改密码",
  //   page: <ResetPasswordByOldPassword />,
  // },
  // {
  //   path: "/profile/订阅计划",
  //   page: <ProfileContent />,
  // },    // {
  //   path: "/:tag1/profile/订阅计划",
  //   page: <ProfileContent />,
  // },
  // {
  //   path: "/:tag1/:tag2/profile/订阅计划",
  //   page: <ProfileContent />,
  // },
  // {
  //   path: "/:tag1/:tag2/:tag3/profile/订阅计划",
  //   page: <ProfileContent />,
  // },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/PDF编辑器-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/PDF编辑器-pre",

    page: <PDFEdit />,
  },

  {
    path: "/压缩PDF-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/压缩PDF-pre",

    page: <CompressPDF />,
  },

  {
    path: "/合并PDF-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/合并PDF-pre",

    page: <MergePDF />,
  },

  {
    path: "/拆分PDF-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/拆分PDF-pre",

    page: <SplitPDF />,
  },

  {
    path: "/旋转PDF-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/旋转PDF-pre",

    page: <RotatePDF />,
  },

  {
    path: "/删除PDF-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/删除PDF-pre",
    page: <DeletePDF />,
  },

  {
    path: "/PDF阅读器-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/PDF阅读器-pre",

    page: <PDFReader />,
  },

  {
    path: "/PDF转Word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/PDF转Word-pre",

    page: <PDFToWord />,
  },

  {
    path: "/Word转PDF-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/Word转PDF-pre",

    page: <WordToPDF />,
  },

  {
    path: "/PPT转PDF-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/PPT转PDF-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/Excel转PDF-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/Excel转PDF-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/PDF转JPG-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/PDF转JPG-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/图片转PDF-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/图片转PDF-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/PDF转PNG",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/PDF转PNG",

    page: <PDFToJpg />,
  },

  {
    path: "/PNG转PDF",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/PNG转PDF",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf转化为pdfa",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf转化为pdfa",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf转化为pdfa-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf转化为pdfa-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf转换为excel",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf转换为excel",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf转换为excel-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf转换为excel-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf转换为pptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf转换为pptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf转换为pptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf转换为pptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html转换为pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html转换为pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html转换为pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html转换为pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default zhRoutJSON;
