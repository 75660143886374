import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const koRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //PDF를-단어로
  {
    path: "/PDF를-단어로",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/PDF를-단어로",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf를-jpg로",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf를-jpg로",
    page: <PDFToJpg />,
  },

  // 단어를-PDF로
  {
    path: "/단어를-PDF로",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/단어를-PDF로",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/이미지를-PDF로",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/이미지를-PDF로",
    page: <ImageToPDF />,
  },

  // pdf로-엑셀
  {
    path: "/pdf로-엑셀",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/pdf로-엑셀",
    page: <ExcelToPDF />,
  },

  // ppt를-pdf로
  {
    path: "/ppt를-pdf로",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt를-pdf로",
    page: <PPTToPDF />,
  },

  // pdf-압축
  {
    path: "/pdf-압축",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/pdf-압축",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/pdf-병합",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/pdf-병합",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/pdf-분할",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/pdf-분할",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-리더
  {
    path: "/pdf-리더",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf-리더",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/pdf-삭제",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/pdf-삭제",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/pdf-회전",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/pdf-회전",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf-편집기",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-편집기",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/pdf-편집기-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-편집기-pre",

    page: <PDFEdit />,
  },

  {
    path: "/pdf-압축-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/pdf-압축-pre",

    page: <CompressPDF />,
  },

  {
    path: "/pdf-병합-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/pdf-병합-pre",

    page: <MergePDF />,
  },

  {
    path: "/pdf-분할-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/pdf-분할-pre",

    page: <SplitPDF />,
  },

  {
    path: "/pdf-회전-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/pdf-회전-pre",

    page: <RotatePDF />,
  },

  {
    path: "/pdf-삭제-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/pdf-삭제-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-리더-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-리더-pre",

    page: <PDFReader />,
  },

  {
    path: "/PDF를-단어로-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/PDF를-단어로-pre",

    page: <PDFToWord />,
  },

  {
    path: "/단어를-PDF로-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/단어를-PDF로-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt를-pdf로-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt를-pdf로-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/pdf로-엑셀-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/pdf로-엑셀-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf를-jpg로-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf를-jpg로-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/이미지를-PDF로-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/이미지를-PDF로-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/png-에서-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-에서-pdf",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-에서-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-에서-png",

    page: <PDFToJpg />,
  },

  //新增功能

  {
    path: "/pdf-및-pdf-오",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-및-pdf-오",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-및-pdf-오-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-및-pdf-오-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf와-x-x-l",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf와-x-x-l",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf와-x-x-l-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf와-x-x-l-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf와-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf와-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf와-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf와-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-및-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-및-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-및-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-및-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default koRoutJSON;
