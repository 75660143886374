import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));

import Home from "../../../page/Home";
const enRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },
  {
    path: ":tag1/:tag2/",
    page: <Home />,
  },
  {
    path: ":tag1/:tag2/:tag3",
    page: <Home />,
  },
  //pdf-to-word
  {
    path: "/pdf-to-word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-to-word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-word",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-word",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/convert",
    page: <MiddlePageOfConvert />,
  },
  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/:tag5/:tag6/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-to-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-to-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-jpg",
    page: <PDFToJpg />,
  },
  // word-to-pdf
  {
    path: "/word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/:tag2/word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/word-to-pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/image-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/image-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/image-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/image-to-pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/:tag2/excel-to-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/excel-to-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt-to-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-to-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/:tag2/ppt-to-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/ppt-to-pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/compress-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/compress-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/:tag2/compress-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/compress-pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/merge-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/merge-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/:tag2/merge-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/merge-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/merge-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/pdf-reader",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf-reader",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/:tag2/pdf-reader",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-reader",
    page: <PDFReader />,
  },
  // delete-pdf
  {
    path: "/delete-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/delete-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/:tag2/delete-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/delete-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/delete-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/rotate-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/rotate-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/:tag2/rotate-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/rotate-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/rotate-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/pdf-editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/pdf-editor",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-editor",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },
  {
    path: "/:tag1/:tag2/subscribe",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/:tag2/:tag3/subscribe",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/pdf-editor-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-editor-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/pdf-editor-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-editor-pre",
    page: <PDFEdit />,
  },
  {
    path: "/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/compress-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/compress-pdf-pre",
    page: <CompressPDF />,
  },
  {
    path: "/merge-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/merge-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/merge-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/merge-pdf-pre",
    page: <MergePDF />,
  },
  {
    path: "/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/split-pdf-pre",
    page: <SplitPDF />,
  },
  {
    path: "/rotate-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/rotate-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/rotate-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/rotate-pdf-pre",
    page: <RotatePDF />,
  },
  {
    path: "/delete-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/delete-pdf-pre",
    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/delete-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/delete-pdf-pre",
    page: <DeletePDF />,
  },
  {
    path: "/pdf-reader-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-reader-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/pdf-reader-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-reader-pre",
    page: <PDFReader />,
  },
  {
    path: "/pdf-to-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-to-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/pdf-to-word-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-to-word-pre",
    page: <PDFToWord />,
  },
  {
    path: "/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/word-to-pdf-pre",
    page: <WordToPDF />,
  },
  {
    path: "/ppt-to-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-to-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/ppt-to-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/ppt-to-pdf-pre",
    page: <PPTToPDF />,
  },
  {
    path: "/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/excel-to-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/excel-to-pdf-pre",
    page: <ExcelToPDF />,
  },
  {
    path: "/pdf-to-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-to-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/pdf-to-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag1/:tag2/:tag3/pdf-to-jpg-pre",
    page: <PDFToJpg />,
  },
  {
    path: "/image-to-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/image-to-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/image-to-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag1/:tag2/:tag3/image-to-pdf-pre",
    page: <ImageToPDF />,
  },

  // 新的convert功能
  // to pdfa
  {
    path: "/pdf-to-pdfa",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-to-pdfa",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-pdfa",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-pdfa",
    page: <PDFToPDFA />,
  },
  // to excel
  {
    path: "/pdf-to-excel",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-to-excel",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-excel",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-excel",
    page: <PDFToExcel />,
  },

  // to ppt

  {
    path: "/pdf-to-pptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag/pdf-to-pptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-pptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-pptx",
    page: <PDFToPPTX />,
  },
  // to html
  {
    path: "/html-to-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-to-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/:tag2/html-to-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/html-to-pdf",
    page: <HtmlToPDF />,
  },

  // to pdfa
  {
    path: "/pdf-to-pdfa-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-to-pdfa-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-pdfa-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-pdfa-pre",
    page: <PDFToPDFA />,
  },
  // to excel
  {
    path: "/pdf-to-excel-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-to-excel-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-excel-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-excel-pre",
    page: <PDFToExcel />,
  },

  // to ppt

  {
    path: "/pdf-to-pptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag/pdf-to-pptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/:tag2/pdf-to-pptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-to-pptx-pre",
    page: <PDFToPPTX />,
  },
  // to html
  {
    path: "/html-to-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-to-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/:tag2/html-to-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/html-to-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default enRoutJSON;
