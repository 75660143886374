import { useContext } from "react";
import { navigate, homeCheck } from "../../utils/navigate";
import { GlobalContext } from "../../utils/globalContext";
import vignetteControl from "../../utils/vignetteControl";
/**
 * a 链接跳转组件,已绑定 navigate 方法
 * @param {Object} props 组件传递参数
 * @param {String} props.href 链接地址,与 a 链接同名属性一致
 * @param {Object} props.style 组件內联样式,JSX 语法中 style 属性已对象形式传入
 * @param {String} props.subDomain 目标地址子域名,清空子域名时需传入空值 ''
 * @param {String} props.options 组件其他属性
 * @param {String} props.position 跳转组件定位属性,描述组件在网站中的位置,用于控制全屏广告展示
 * @param {String} props.children 子组件,包含在 Link组件内的组件即为子组件
 * @returns <JSX/> Link
 */
export default function Link({
  href,
  style,
  subDomain,
  position,
  children,
  ...options
}) {
  // 获取用户订阅状态,以确定全屏广告是否展示
  const { useSubstate } = useContext(GlobalContext);
  return (
    <a
      // 自动生成多域名网址
      href={navigate(href, subDomain)}
      // 修改组件样式
      style={style}
      // 广告控制开关
      data-google-vignette={vignetteControl(
        useSubstate?.status,
        // 为主页链接自动添加 home 前缀
        homeCheck() ? "home_" + position : position
      )}
      // 其他属性
      {...options}
    >
      {/* 子组件 */}
      {children}
    </a>
  );
}
