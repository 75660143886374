import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const skRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-do-slova
  {
    path: "/pdf-do-slova",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf-do-slova",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-do-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-do-jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/word-to-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/word-to-pdf",
    page: <WordToPDF />,
  },

  // obrázok-do-pdf
  {
    path: "/obrázok-do-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/obrázok-do-pdf",
    page: <ImageToPDF />,
  },

  // excel-do-pdf
  {
    path: "/excel-do-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-do-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-do-pdf
  {
    path: "/ppt-do-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-do-pdf",
    page: <PPTToPDF />,
  },

  // komprimovať-pdf
  {
    path: "/komprimovať-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/komprimovať-pdf",
    page: <CompressPDF />,
  },

  // zlúčiť-pdf
  {
    path: "/zlúčiť-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/zlúčiť-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/zlúčiť-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/zlúčiť-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // čítačka-pdf
  {
    path: "/čítačka-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/čítačka-pdf",
    page: <PDFReader />,
  },

  // vymazať-pdf
  {
    path: "/vymazať-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/vymazať-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/vymazať-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/vymazať-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // otočiť-pdf
  {
    path: "/otočiť-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/otočiť-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/otočiť-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/otočiť-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // editor-pdf
  {
    path: "/editor-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/editor-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/editor-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/editor-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/komprimovať-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/komprimovať-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/zlúčiť-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/zlúčiť-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/split-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/split-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/otočiť-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/otočiť-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/vymazať-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/vymazať-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/čítačka-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/čítačka-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-do-slova-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-do-slova-pre",

    page: <PDFToWord />,
  },

  {
    path: "/word-to-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/word-to-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-do-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-do-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-do-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-do-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-do-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-do-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/obrázok-do-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/obrázok-do-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-do-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-do-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-do-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-do-pdf",

    page: <ImageToPDF />,
  },
  //新增功能

  {
    path: "/pdf-a-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-a-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-a-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-a-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-a-súbor",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-a-súbor",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-a-súbor-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-a-súbor-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-a-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-a-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-a-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-a-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-a-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-a-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-a-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-a-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default skRoutJSON;
