// 全局路由管理文件
// REACT
import React from "react";
// ROUTER
import { Alert, Snackbar, Typography, Button, Box, Input } from "@mui/material";
import languages from "../internationalization/language";
import { useTranslation } from "react-i18next";
// GLOBAL全局状态
import { GlobalContext } from "../utils/globalContext";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// COMPONENT
import Home from "../page/Home";
import Loading from "../page/toolpage/component/Loading";

import afRoutJSON from "./locales/af/index.js";
import afZaRoutJSON from "./locales/af-ZA/index.js";
import beRoutJSON from "./locales/be/index.js";
import deRoutJSON from "./locales/de/index.js";
import enRoutJSON from "./locales/en/index.js";
import filRoutJSON from "./locales/fil-PH/index.js";
import frRoutJSON from "./locales/fr/index.js";
import jaRoutJSON from "./locales/ja/index.js";
import koRoutJSON from "./locales/ko/index.js";
import ltRoutJSON from "./locales/lt/index.js";
import msRoutJSON from "./locales/ms/index.js";
import nlRoutJSON from "./locales/nl/index.js";
import plRoutJSON from "./locales/pl/index.js";
import plPlRoutJSON from "./locales/pl-PL/index.js";
import ptRoutJSON from "./locales/pt/index.js";
import roRoutJSON from "./locales/ro/index.js";
import ruRoutJSON from "./locales/ru/index.js";
import skRoutJSON from "./locales/sk/index.js";
import slRoutJSON from "./locales/sl/index.js";
import sqRoutJSON from "./locales/sq/index.js";
import srRoutJSON from "./locales/sr/index.js";
import svRoutJSON from "./locales/sv/index.js";
import taRoutJSON from "./locales/ta/index.js";
import thRoutJSON from "./locales/th/index.js";
import trRoutJSON from "./locales/tr/index.js";
import ukRoutJSON from "./locales/uk/index.js";
import viRoutJSON from "./locales/vi/index.js";
import zhRoutJSON from "./locales/zh/index.js";
import arRoutJSON from "./locales/ar/index.js";

// 懒加载路由
const Result = React.lazy(() => import("../page/Result"));
const CompressPDF = React.lazy(() => import("../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../page/toolpage/ImageToPDF"));

const WordToPDF = React.lazy(() => import("../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() => import("../page/toolpage/PDFEditor/PDFEditWorkSpace"));
const PDFToJpg = React.lazy(() => import("../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() => import("../page/toolpage/SplitPDF/SplitPDFWorkSpace"));
const MergePDFWorkSpace = React.lazy(() => import("../page/toolpage/MergePDF/MergePDFWorkSpace"));
const RotatePDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../page/Profile/ResetPasswordByOldPassword"),
);
// const SubscribePage = React.lazy(() => import("../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../page/Support"));
const DownloadPage = React.lazy(() => import("../page/DownloadPage"));
let multiFunction = [
  // 英语
  {
    path: "/png-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/png-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/png-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/png-to-pdf",
    page: <ImageToPDF />,
  },
  // - - - - - - - - - - - -  - -
  {
    path: "/jpg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpg-to-pdf",
    page: <ImageToPDF />,
  },
  // - - - - - - - - - - -
  {
    path: "/jpeg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpeg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpeg-to-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpeg-to-pdf",
    page: <ImageToPDF />,
  },
  // to image
  // - - - - -  - - - - -
  // 德语
  {
    path: "/png-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/png-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/png-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/png-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/jpg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/jpeg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpeg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpeg-zu-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpeg-zu-pdf",
    page: <ImageToPDF />,
  },

  // to jpg
  {
    path: "/pdf-zu-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-zu-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/pdf-zu-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-zu-png",
    page: <PDFToJpg />,
  },
  // 泰语

  {
    path: "/png-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/png-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/png-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/png-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/jpeg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpeg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpeg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpeg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/jpg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/jpg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/jpg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/jpg-เป็น-pdf",
    page: <ImageToPDF />,
  },
  // to jpg
  {
    path: "/pdf-เป็น-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-เป็น-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/pdf-เป็น-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-เป็น-png",
    page: <PDFToJpg />,
  },
  // 葡萄牙语
  {
    path: "/pdf-para-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-para-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/pdf-para-png",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/:tag2/:tag3/pdf-para-png",
    page: <PDFToJpg />,
  },

  // 分割
  {
    path: "/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/:tag2/:tag3/split-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/split-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
];
export default function Router() {
  const { t, i18n } = useTranslation();
  // 引入全局属性
  const { fileEncryption, setFileEncryption, snackInfo, language } =
    React.useContext(GlobalContext);

  const [open, setOpen] = React.useState(false);
  //文件加密弹出
  //关闭

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFileEncryption(false);
  };

  let [languageRoute, setLanguageRoute] = React.useState([
    ...afRoutJSON,
    ...afZaRoutJSON,
    ...beRoutJSON,
    ...deRoutJSON,
    ...enRoutJSON,
    ...filRoutJSON,
    ...frRoutJSON,
    ...jaRoutJSON,
    ...koRoutJSON,
    ...ltRoutJSON,
    ...msRoutJSON,
    ...nlRoutJSON,
    ...plRoutJSON,
    ...plPlRoutJSON,
    ...ptRoutJSON,
    ...roRoutJSON,
    ...ruRoutJSON,
    ...skRoutJSON,
    ...slRoutJSON,
    ...sqRoutJSON,
    ...srRoutJSON,
    ...svRoutJSON,
    ...taRoutJSON,
    ...thRoutJSON,
    ...trRoutJSON,
    ...ukRoutJSON,
    ...viRoutJSON,
    ...zhRoutJSON,
    ...arRoutJSON,
  ]);

  return (
    <BrowserRouter>
      {/* 路由模式 */}
      <Routes history={history}>
        {languageRoute &&
          languageRoute.map((item, index) => {
            return (
              <Route
                key={index}
                path={decodeURI(item.path.replaceAll(" ", "-"))}
                element={<React.Suspense fallback={<Loading />}>{item.page}</React.Suspense>}
              >
                {item.path1 && (
                  <Route
                    path={decodeURI(item.path1.replaceAll(" ", "-"))}
                    element={<React.Suspense fallback={<Loading />}>{item.page1}</React.Suspense>}
                  ></Route>
                )}
                {item.path2 && (
                  <Route
                    path={decodeURI(item.path2.replaceAll(" ", "-"))}
                    element={<React.Suspense fallback={<Loading />}>{item.page2}</React.Suspense>}
                  ></Route>
                )}
                <Route
                  index
                  element={<React.Suspense fallback={<Loading />}>{item.page2}</React.Suspense>}
                />
              </Route>
            );
          })}
        {/* <Route
          path=":tag/pdf转word"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        /> */}

        {/* 主页 */}
        {/* <Route path="/" element={<Home />} />
        <Route path=":tag/" element={<Home />} />
        <Route path=":tag1/:tag2/" element={<Home />} />
        <Route
          path=":tag1/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />

        <Route
          path=":tag/pdf-edit-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEditWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/pdf-edit-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEditWorkSpace />
            </React.Suspense>
          }
        />


        <Route
          path="/pdf-editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}

        {multiFunction.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={<React.Suspense fallback={<Loading />}>{item.page}</React.Suspense>}
            />
          );
        })}
        <Route
          path="/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        <Route
          path="/:tag1/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        <Route
          path="/:tag1/:tag2/convert/result/download"
          element={
            <React.Suspense fallback={<Loading />}>
              <DownloadPage />
            </React.Suspense>
          }
        />
        <Route
          path="/pdf-editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/pdf-editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-editor-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-editor-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFEdit />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/split-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/split-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/rotate-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/rotate-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/delete-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/delete-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/merge-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/merge-pdf-work-space"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDFWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path="/compress-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/compress-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/compress-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        {/* 复制以上的信息  */}
        <Route
          path="/compress-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/compress-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/compress-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <CompressPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/merge-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/merge-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/merge-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/merge-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/merge-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/merge-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <MergePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/split-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/split-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/split-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/split-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/split-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/split-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <SplitPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/rotate-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/rotate-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/rotate-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/rotate-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/rotate-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/rotate-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/delete-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/delete-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/delete-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/delete-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/delete-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/delete-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/delete-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <DeletePDF />
            </React.Suspense>
          }
        />
        <Route
          path="/pdf-reader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-reader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-reader"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/pdf-reader-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-reader-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-reader-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFReader />
            </React.Suspense>
          }
        />
        <Route
          path="/pdf-to-word"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-to-word"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-to-word"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/pdf-to-word-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-to-word-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-to-word-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToWord />
            </React.Suspense>
          }
        />
        <Route
          path="/word-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/word-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/word-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        {/* 复制以上的信息 */}
        <Route
          path="/word-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/word-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/word-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <WordToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/ppt-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ppt-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ppt-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        {/* 复制上面的信息 */}
        <Route
          path="/ppt-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ppt-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ppt-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PPTToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/excel-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/excel-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/excel-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        {/* 复制以上的信息 */}
        <Route
          path="/excel-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/excel-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/excel-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <ExcelToPDF />
            </React.Suspense>
          }
        />
        <Route
          path="/pdf-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/pdf-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/pdf-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />
        {/* 复制以上的信息 */}
        <Route
          path="/pdf-to-jpg-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <PDFToJpg />
            </React.Suspense>
          }
        />

        <Route
          path=":tag/image-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/image-to-pdf"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />
        {/* 复制以上的信息 */}
        <Route
          path="/image-to-pdf-preview"
          element={
            <React.Suspense fallback={<Loading />}>
              <ImageToPDF />
            </React.Suspense>
          }
        />

        <Route
          path="/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/share/result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/convert/result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/convert/result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/convert/result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/:tag4/convert/result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        {/* <Route
          path="/subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        /> */}
        <Route
          path="/profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password-by-old-password"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag/profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password-by-old-password"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag1/:tag2/profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="reset-password-by-old-password"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>

        {/* 找不到页面 */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
      {/* 加密提示 */}
      <Snackbar sx={{}} open={fileEncryption}>
        <Alert variant="filled" severity="info" onClose={handleClose}>
          {snackInfo.title == "File is encrypted" && (
            <Box>
              <Typography>Please Input:</Typography>
              <Input
                placeholder="PDF Password"
                sx={{
                  color: "#ffffff",
                  ":after": {
                    borderBottom: "2px solid #ffffff",
                  },
                  ":before": {
                    borderBottom: "2px solid #fffafa",
                  },
                }}
              />
              <Button variant="text">Confirm</Button>
            </Box>
          )}
          {snackInfo.title != "File is encrypted" && (
            <Box>
              <Typography variant="body5" component="p">
                {snackInfo.title}
              </Typography>
              <Typography variant="caption">{snackInfo.message}</Typography>
            </Box>
          )}
        </Alert>
      </Snackbar>
      {/* 发送邮件  */}
      <Button
        onClick={() => {
          window.open("/static/about.html");
        }}
        variant="contained"
        sx={{
          borderRadius: "50%",
          width: "64px",
          height: "64px",
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
      >
        <Box
          sx={{
            marginTop: "9px",
          }}
        >
          <AttachEmailIcon />
        </Box>
      </Button>
    </BrowserRouter>
  );
}
