// PDFToolList
// 引入功能模块
// REACT
import React from "react";
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import getAllUrlParams from "../../utils/getAllUrlParams";
import { GlobalContext } from "../../utils/globalContext";
import { useTranslation } from "react-i18next";
// DATA
// PDF工具列表数据包
import { allToolList } from "../../data";
import { Trans } from "react-i18next/icu.macro";
import Link from "./Link";

// 引入新的静态首页的css
import "../../css/staticHome.css";
function ToolCardNew({ tool }) {
  const { t } = useTranslation();
  // 获取错误页面信息
  const { getLastReferrer, language } = React.useContext(GlobalContext);
  let [isHome, setIsHome] = React.useState(true);
  React.useEffect(async () => {
    const currentPageUrl = await getLastReferrer();
    if (currentPageUrl.currentPage !== "/") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, []);
  return (
    <Grid
      item
      container
      xs={11.7}
      sm={6}
      md={4}
      xl={3}
      // 获取用户点击的次数----
      sx={{
        display:
          /Mobi|Android|iPhone/i.test(navigator.userAgent) === true && tool.ID === 12
            ? "none"
            : "block",
        "&>a": {
          textDecorationLine: "none",
          width: "100%",
        },
      }}
    >
      {/* {console.log("123123", t(tool.path))} */}
      <Link
        href={t(tool.pathPreview + "view").replaceAll(" ", "-")}
        subDomain={tool.domain}
        position="function_tool_list"
      >
        {/* 工具卡片 */}
        <Box
          sx={{
            color: "#000000",
            backgroundColor: "ffffff",
            // border: "0.6px solid rgba(238, 238, 238,0.4)",
            maxHeight: "252px",
            width: "100%",
            padding: "0px",
            maxWidth: "500px",
            margin: {
              xl: "10px 20px 10px 20px",
              md: "5px 10px",
              sm: "4px 8px",
              xs: "2px 5px",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // boxShadow: "0px 0.2px 10px  rgba(0, 0, 0, 0.03)",
            // "&:hover": { boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)" },
          }}
          onClick={() => {
            sendConstructEventByEventParams("open_function", {});
            isPC && sendConstructEventByEventParams("open_function_pc", {});
            isHome &&
              sendConstructEventByEventParams("home", {
                click_tools: tool.name,
              });
            !isHome &&
              sendConstructEventByEventParams("select_file_page", {
                click_tools: tool.name,
              });
          }}
        >
          <Box>
            {/* 工具图标 */}
            {tool.arrow !== "none" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& img": {
                    marginRight: "10px",
                  },
                  marginBottom: {
                    xl: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "10px",
                  },
                }}
              >
                <img
                  src={"/images/icon/" + tool.beforeToolIcon}
                  style={{ width: { xs: "36px", sm: "48px", md: "48px", xl: "48px" } }}
                  alt={tool.imgAlt}
                />
                <img
                  src={"/images/icon/" + tool.arrow}
                  style={{ width: { xs: "36px", sm: "48px", md: "48px", xl: "48px" } }}
                  alt={tool.imgAlt}
                />
                <img
                  src={"/images/icon/" + tool.afterToolIcon}
                  style={{ width: { xs: "36px", sm: "48px", md: "48px", xl: "48px" } }}
                  alt={tool.imgAlt}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: {
                    xl: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "10px",
                  },
                }}
              >
                <img
                  src={"/images/icon/" + tool.beforeToolIcon}
                  style={{ width: { xs: "36px", sm: "48px", md: "48px", xl: "48px" } }}
                  alt={tool.imgAlt}
                />
              </Box>
            )}

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              {/* 工具名称 */}
              <Typography
                // variant="body2"
                sx={{
                  fontSize: { xl: "24px", md: "20px", sm: "18px", xs: "16px" },
                  fontWeight: "700",
                  paddingLeft: "10px",
                  // marginBottom: { xl: "20px", md: "20px", sm: "10px", xs: "0" },
                }}
              >
                <Trans i18nKey={tool.context.name.default}>{tool.context.name.default}</Trans>
              </Typography>
              {/* 工具描述 */}
              <Typography
                // variant="body5"
                sx={{
                  marginBottom: "20px",
                  fontSize: { xl: "18px", md: "16px", sm: "14px", xs: "14px" },
                  overflow: "hidden",
                  height: "3.6rem",
                  lineHeight: "1.24rem",
                  textOverflow: "ellipsis",
                  boxSizing: "content-box",
                  display: "-webkit-box",
                  /* autoprefixer: off */
                  WebkitBoxOrient: "vertical",
                  /* autoprefixer: on */
                  WebkitLineClamp: "3",
                }}
              >
                <Trans i18nKey={tool.context.description.default}>
                  {tool.context.description.default}
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
}

// Templates PDF模板区域
function TemplatesToolCard(props) {
  // 当前是否选中
  const [isSelect, setIsSelect] = React.useState(false);

  return (
    <Grid
      item
      container
      xs={6}
      sm={4}
      md={3}
      xl={3}
      sx={{
        "&>a": {
          textDecorationLine: "none",
          width: "100%",
        },
      }}
    >
      <Link href={props.tool.path} subDomain={props.tool.domain} position="function_tool_list">
        <Card
          onMouseEnter={() => {
            setIsSelect(true);
          }}
          onMouseLeave={() => {
            setIsSelect(false);
          }}
          sx={{
            border: "1px solid #D0D4E4",
            height: "210px",
            width: "100%",
            maxWidth: "272px",
            padding: "0px",
            boxShadow: "0px 0.2px 10px  rgba(0, 0, 0, 0.03)",
            "&:hover": { boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)" },
          }}
          onClick={() => {
            sendConstructEventByEventParams("request_ Banner", {});
            sendConstructEventByEventParams("open_function", {});
            isPC && sendConstructEventByEventParams("open_function_pc", {});
            sendConstructEventByEventParams("home", {
              click_tools: props.tool.EventCategroy,
            });
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              padding: "0px",
              ":last-child": {
                paddingBottom: "0px",
              },
            }}
          >
            {/* 工具图标 */}
            {props.tool.isImage ? (
              <Box
                bgcolor={props.tool.fill}
                sx={{
                  width: "100%",
                  display: "flex",
                  height: "144px",
                }}
              >
                <img src={"/images/icon/" + props.tool.beforeToolIcon} style={{ width: "100%" }} />
              </Box>
            ) : (
              <Box
                bgcolor={props.tool.fill}
                sx={{
                  width: "100%",
                  display: "flex",
                  height: "144px",
                }}
              >
                <img src={"/images/icon/" + props.tool.beforeToolIcon} style={{ width: "100%" }} />
              </Box>
            )}
            <Box>
              {/* 工具名称 */}

              <Typography
                // variant="body2"
                sx={{
                  fontSize: { xl: "16px", md: "16px", sm: "16px", xs: "16px" },
                  fontWeight: "500",
                  lineHeight: "66px",
                  width: "100%",
                  color: "#323338",
                  letterSpacing: "0.02px",
                  textAlign: "center",
                }}
              >
                <Trans i18nKey={props.tool.toolName}>{props.tool.toolName}</Trans>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

// MAIN
export default function PDFToolList() {
  // 模板列表,暂时没有模板
  // const templatesToolList = TemplatesToolList();
  let [homeToolList, setHomeToolList] = React.useState([]);
  React.useEffect(() => {
    if (getAllUrlParams(window.location.href).toolListSort !== undefined) {
      let toolListSort = getAllUrlParams(window.location.href).toolListSort;
      // 现对获取的参数进行判断
      toolListSort = decodeURI(toolListSort);
      //  将 中括号里面的内容 拿出来
      toolListSort = toolListSort.slice(toolListSort.indexOf("[") + 1, toolListSort.indexOf("]"));
      console.log("[ toolListSort ] >", toolListSort);
      toolListSort = toolListSort.split(",");
      toolListSort.forEach((item, index) => {
        if (item > 12 || item < 0) {
          logi("请输入0~12");
        } else {
          homeToolList[index] = allToolList[item];
          setHomeToolList(homeToolList);
        }
      });
    } else {
      setHomeToolList(allToolList);
    }
  }, []);
  return (
    <Box sx={{ position: "relative" }}>
      {/* 标题 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: { xl: "48px", md: "40px", sm: "32px", xs: "24px" },
            textAlign: "center",
            marginTop: { lg: "50px", md: "40px", sm: "25px", xs: "20px" },
            marginBottom: { lg: "50px", md: "40px", sm: "25px", xs: "20px" },
          }}
        >
          <Trans i18nKey="All PDF Tools">{"All PDF Tools"}</Trans>
        </Typography>
        <Typography
          component={"p"}
          sx={{
            width: { xs: "80%", md: "600px" },
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          <Trans i18nKey="A powerful and easy-to-use PDF tool, here you can choose to convert, merge, compress, rotate PDF, and it's completely free！"></Trans>
        </Typography>
      </Box>
      {/* 工具列表 */}
      {/* <Box
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1200px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={1}>
          {allToolList.map((tool) => {
            return <ToolCard key={tool.key} tool={tool} />;
          })}
        </Grid>
      </Box> */}

      {/* 工具列表 */}
      <Box
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1200px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={1}>
          {homeToolList.map((tool) => {
            return <ToolCardNew key={tool.key} tool={tool} />;
          })}
        </Grid>
      </Box>

      {/* template */}
      {/* <Box sx={{ flexGrow: 1, margin: "auto", maxWidth: "1200px",marginTop:"20px" }}>
        <Typography
          // variant="body2"
          sx={{
            height:"20px",
            fontSize: { xl: "14px", md: "14px", sm: "14px", xs: "14px" },
            fontWeight: "500",
            width: "136px",
            letterSpacing: "0.02px",
            color: "#676879",
            marginBottom:"12px"
          }}
        >
          Templates
        </Typography>
        <Grid container spacing={1}>
          {templatesToolList.map((tool) => {
            return <TemplatesToolCard key={tool.EventCategroy} tool={tool} />;
          })}
        </Grid>
      </Box> */}
    </Box>
  );
}
