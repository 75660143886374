import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const frRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf-to-word
  {
    path: "/pdf en mot",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf en mot",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convertir",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convertir",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convertir",
    page: <MiddlePageOfConvert />,
  },

  //   résultat
  {
    path: "/:tag1/résultat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/résultat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/résultat",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/résultat",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/espace de travail d'édition pdf",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/espace de travail d'édition pdf",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf en jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf en jpg",
    page: <PDFToJpg />,
  },

  // word-to-pdf
  {
    path: "/mot en pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/mot en pdf",
    page: <WordToPDF />,
  },

  // Image-to-pdf
  {
    path: "/image en pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/image en pdf",
    page: <ImageToPDF />,
  },

  // excel-to-pdf
  {
    path: "/excel en pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel en pdf",
    page: <ExcelToPDF />,
  },

  // ppt-to-pdf
  {
    path: "/ppt en pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt en pdf",
    page: <PPTToPDF />,
  },

  // compress-pdf
  {
    path: "/compresser pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/compresser pdf",
    page: <CompressPDF />,
  },

  // merge-pdf
  {
    path: "/fusionner PDF",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/fusionner PDF",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/merge espace de travail pdf",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/merge espace de travail pdf",
    page: <MergePDFWorkSpace />,
  },

  // split-pdf
  {
    path: "/diviser pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/diviser pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/split pdf espace de travail",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/split pdf espace de travail",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-reader
  {
    path: "/lecteur PDF",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/lecteur PDF",
    page: <PDFReader />,
  },

  // delete-pdf
  {
    path: "/supprimer pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/supprimer pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/supprimer l'espace de travail pdf",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/supprimer l'espace de travail pdf",
    page: <DeletePDFWorkSpace />,
  },

  // rotate-pdf
  {
    path: "/tourner pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/tourner pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/rotate pdf work space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-editor
  {
    path: "/éditeur pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/éditeur pdf",
    page: <PDFEdit />,
  },

  //s'abonner
  {
    path: "/s'abonner",
    page: <SubscribePage />,
  },
  {
    path: "/:tag1/s'abonner",
    page: <SubscribePage />,
  },

  // profile
  {
    path: "/profil",
    page: <Profile />,
    path1: "/profil/réinitialiser le mot de passe par l'ancien mot de passe",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profil",
    page: <Profile />,
    path1: "/:tag1/profil/réinitialiser le mot de passe par l'ancien mot de passe",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profil",
    page: <Profile />,
    path1: "/:tag1/:tag2/profil/réinitialiser le mot de passe par l'ancien mot de passe",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profil/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profil",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profil/réinitialiser le mot de passe par l'ancien mot de passe",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profil/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },

  // 新增的页面
  {
    path: "/éditeur-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/éditeur-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/compresser-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/compresser-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/fusionner-PDF-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/fusionner-PDF-pre",

    page: <MergePDF />,
  },

  {
    path: "/diviser-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/diviser-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/tourner-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/tourner-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/supprimer-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/supprimer-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/lecteur-PDF-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/lecteur-PDF-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf-en-mot-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf-en-mot-pre",

    page: <PDFToWord />,
  },

  {
    path: "/mot-en-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/mot-en-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-en-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-en-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-en-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-en-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-en-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-en-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/image-en-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/image-en-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-en-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-en-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-en-pdf",

    page: <ImageToPDF />,
  },
  {
    path: "/:tag/png-en-pdf",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-et-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-et-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-et-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-et-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-et-fichier",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-et-fichier",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-et-fichier-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-et-fichier-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-et-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-et-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-et-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-et-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-et-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-et-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-et-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-et-pdf-pre",
    page: <HtmlToPDF />,
  },
];

export default frRoutJSON;
