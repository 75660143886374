import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const viRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //chuyển-PDF-sang-văn-bản
  {
    path: "/chuyển-PDF-sang-văn-bản",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/chuyển-PDF-sang-văn-bản",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf-sang-jpg",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf-sang-jpg",
    page: <PDFToJpg />,
  },

  // từ-sang-pdf
  {
    path: "/từ-sang-pdf",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/từ-sang-pdf",
    page: <WordToPDF />,
  },

  // hình-ảnh-sang-pdf
  {
    path: "/hình-ảnh-sang-pdf",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/hình-ảnh-sang-pdf",
    page: <ImageToPDF />,
  },

  // excel-sang-pdf
  {
    path: "/excel-sang-pdf",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/excel-sang-pdf",
    page: <ExcelToPDF />,
  },

  // ppt-sang-pdf
  {
    path: "/ppt-sang-pdf",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt-sang-pdf",
    page: <PPTToPDF />,
  },

  // nén-pdf
  {
    path: "/nén-pdf",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/nén-pdf",
    page: <CompressPDF />,
  },

  // hợp-nhất-pdf
  {
    path: "/hợp-nhất-pdf",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/hợp-nhất-pdf",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/hợp-nhất-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/hợp-nhất-pdf-work-space",
    page: <MergePDFWorkSpace />,
  },

  // tách-pdf
  {
    path: "/tách-pdf",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/tách-pdf",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/tách-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/tách-pdf-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // trình-đọc-pdf
  {
    path: "/trình-đọc-pdf",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/trình-đọc-pdf",
    page: <PDFReader />,
  },

  // xóa-pdf
  {
    path: "/xóa-pdf",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/xóa-pdf",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/xóa-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/xóa-pdf-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // xoay-pdf
  {
    path: "/xoay-pdf",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/xoay-pdf",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/xoay-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/xoay-pdf-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // trình-chỉnh-sửa-pdf
  {
    path: "/trình-chỉnh-sửa-pdf",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/trình-chỉnh-sửa-pdf",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/trình-chỉnh-sửa-pdf-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/trình-chỉnh-sửa-pdf-pre",

    page: <PDFEdit />,
  },

  {
    path: "/nén-pdf-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/nén-pdf-pre",

    page: <CompressPDF />,
  },

  {
    path: "/hợp-nhất-pdf-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/hợp-nhất-pdf-pre",

    page: <MergePDF />,
  },

  {
    path: "/tách-pdf-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/tách-pdf-pre",

    page: <SplitPDF />,
  },

  {
    path: "/xoay-pdf-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/xoay-pdf-pre",

    page: <RotatePDF />,
  },

  {
    path: "/xóa-pdf-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/xóa-pdf-pre",
    page: <DeletePDF />,
  },

  {
    path: "/trình-đọc-pdf-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/trình-đọc-pdf-pre",

    page: <PDFReader />,
  },

  {
    path: "/chuyển-PDF-sang-văn-bản-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/chuyển-PDF-sang-văn-bản-pre",

    page: <PDFToWord />,
  },

  {
    path: "/từ-sang-pdf-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/từ-sang-pdf-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt-sang-pdf-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt-sang-pdf-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/excel-sang-pdf-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/excel-sang-pdf-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf-sang-jpg-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-sang-jpg-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/hình-ảnh-sang-pdf-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/hình-ảnh-sang-pdf-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf-sang-png",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf-sang-png",

    page: <PDFToJpg />,
  },

  {
    path: "/png-sang-pdf",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png-sang-pdf",

    page: <ImageToPDF />,
  },
  //新增功能

  {
    path: "/pdf-và-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-và-pdf-a",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-và-pdf-a-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-và-pdf-a-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-và-tập-tin",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-và-tập-tin",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-và-tập-tin-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-và-tập-tin-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-và-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-và-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-và-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-và-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-và-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-và-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-và-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-và-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default viRoutJSON;
