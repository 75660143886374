import React from "react";
const Result = React.lazy(() => import("../../../page/Result"));
const CompressPDF = React.lazy(() => import("../../../page/toolpage/CompressPDF"));
const DeletePDF = React.lazy(() => import("../../../page/toolpage/DeletePDF"));
const ExcelToPDF = React.lazy(() => import("../../../page/toolpage/ExcelToPDF"));
const ImageToPDF = React.lazy(() => import("../../../page/toolpage/ImageToPDF"));
const WordToPDF = React.lazy(() => import("../../../page/toolpage/WordToPDF"));
const MergePDF = React.lazy(() => import("../../../page/toolpage/MergePDF"));
const PDFReader = React.lazy(() => import("../../../page/toolpage/PDFReader"));
const PDFEdit = React.lazy(() => import("../../../page/toolpage/PDFEditor"));
const PDFEditWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/PDFEditor/PDFEditWorkSpace"),
);
const PDFToJpg = React.lazy(() => import("../../../page/toolpage/PDFToJPG"));
const SplitPDF = React.lazy(() => import("../../../page/toolpage/SplitPDF"));
const PPTToPDF = React.lazy(() => import("../../../page/toolpage/PPTToPDF"));
const RotatePDF = React.lazy(() => import("../../../page/toolpage/RotatePDF"));
const PDFToWord = React.lazy(() => import("../../../page/toolpage/PDFToWord"));
const Profile = React.lazy(() => import("../../../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../../../page/Profile/ProfileContent"));
const SplitPDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/SplitPDF/SplitPDFWorkSpace"),
);
const MergePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/MergePDF/MergePDFWorkSpace"),
);
const RotatePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/RotatePDF/RotatePDFWorkSpace"),
);
const DeletePDFWorkSpace = React.lazy(() =>
  import("../../../page/toolpage/DeletePDF/DeletePDFWorkSpace"),
);
const ForgetPassWeb = React.lazy(() => import("../../../page/component/User/ForgetPassWeb"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../../../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../../../page/subscribe/SubscribePage"));
const MiddlePageOfConvert = React.lazy(() => import("../../../page/toolpage/MiddlePageOfConvert"));
const Support = React.lazy(() => import("../../../page/Support"));
const PDFToPDFA = React.lazy(() => import("../../../page/toolpage/PDFToPDFA"));
const PDFToExcel = React.lazy(() => import("../../../page/toolpage/PDFToExcel"));
const PDFToPPTX = React.lazy(() => import("../../../page/toolpage/PDFToPPTX"));
const HtmlToPDF = React.lazy(() => import("../../../page/toolpage/HTMLToPDF"));
import Home from "../../../page/Home";
const trRoutJSON = [
  // Home
  {
    path: "/",
    page: <Home />,
  },
  {
    path: ":tag1/",
    page: <Home />,
  },

  //pdf'den-kelimeye
  {
    path: "/pdf'den-kelimeye",
    page: <PDFToWord />,
  },
  {
    path: "/:tag1/pdf'den-kelimeye",
    page: <PDFToWord />,
  },

  //   convert
  {
    path: "/:tag1/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/convert",
    page: <MiddlePageOfConvert />,
  },
  {
    path: "/:tag1/:tag2/:tag3/convert",
    page: <MiddlePageOfConvert />,
  },

  //   result
  {
    path: "/:tag1/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/result",
    page: <Result />,
  },
  {
    path: "/:tag1/:tag2/:tag3/:tag4/result",
    page: <Result />,
  },

  // PDFEditWorkSpace
  {
    path: "/:tag1/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf-edit-work-space",
    page: <PDFEditWorkSpace />,
  },

  {
    path: "/pdf'den-jpg'ye",
    page: <PDFToJpg />,
  },
  {
    path: "/:tag1/pdf'den-jpg'ye",
    page: <PDFToJpg />,
  },

  // kelimeden-pdf'ye
  {
    path: "/kelimeden-pdf'ye",
    page: <WordToPDF />,
  },
  {
    path: "/:tag1/kelimeden-pdf'ye",
    page: <WordToPDF />,
  },

  // resmi-pdf'ye-çevir
  {
    path: "/resmi-pdf'ye-çevir",
    page: <ImageToPDF />,
  },
  {
    path: "/:tag1/resmi-pdf'ye-çevir",
    page: <ImageToPDF />,
  },

  // pdf'ye-excel
  {
    path: "/pdf'ye-excel",
    page: <ExcelToPDF />,
  },
  {
    path: "/:tag1/pdf'ye-excel",
    page: <ExcelToPDF />,
  },

  // ppt'den-pdf'ye
  {
    path: "/ppt'den-pdf'ye",
    page: <PPTToPDF />,
  },
  {
    path: "/:tag1/ppt'den-pdf'ye",
    page: <PPTToPDF />,
  },

  // pdf'yi-sıkıştır
  {
    path: "/pdf'yi-sıkıştır",
    page: <CompressPDF />,
  },
  {
    path: "/:tag1/pdf'yi-sıkıştır",
    page: <CompressPDF />,
  },

  // PDF-birleştirme
  {
    path: "/PDF-birleştirme",
    page: <MergePDF />,
  },
  {
    path: "/:tag1/PDF-birleştirme",
    page: <MergePDF />,
  },

  {
    path: "/:tag1/PDF-birleştirme-work-space",
    page: <MergePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/PDF-birleştirme-work-space",
    page: <MergePDFWorkSpace />,
  },

  // pdf'yi-böl
  {
    path: "/pdf'yi-böl",
    page: <SplitPDF />,
  },
  {
    path: "/:tag1/pdf'yi-böl",
    page: <SplitPDF />,
  },

  {
    path: "/:tag1/pdf'yi-böl-work-space",
    page: <SplitPDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf'yi-böl-work-space",
    page: <SplitPDFWorkSpace />,
  },

  // pdf-okuyucu
  {
    path: "/pdf-okuyucu",
    page: <PDFReader />,
  },
  {
    path: "/:tag1/pdf-okuyucu",
    page: <PDFReader />,
  },

  // pdf'yi-sil
  {
    path: "/pdf'yi-sil",
    page: <DeletePDF />,
  },
  {
    path: "/:tag1/pdf'yi-sil",
    page: <DeletePDF />,
  },

  {
    path: "/:tag1/pdf'yi-sil-work-space",
    page: <DeletePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf'yi-sil-work-space",
    page: <DeletePDFWorkSpace />,
  },

  // pdf'yi-döndür
  {
    path: "/pdf'yi-döndür",
    page: <RotatePDF />,
  },
  {
    path: "/:tag1/pdf'yi-döndür",
    page: <RotatePDF />,
  },

  {
    path: "/:tag1/pdf'yi-döndür-work-space",
    page: <RotatePDFWorkSpace />,
  },
  {
    path: "/:tag1/:tag2/pdf'yi-döndür-work-space",
    page: <RotatePDFWorkSpace />,
  },

  // pdf-düzenleyici
  {
    path: "/pdf-düzenleyici",
    page: <PDFEdit />,
  },
  {
    path: "/:tag1/pdf-düzenleyici",
    page: <PDFEdit />,
  },

  //subscribe
  // {
  //   path: "/subscribe",
  //   page: <SubscribePage />,
  // },
  // {
  //   path: "/:tag1/subscribe",
  //   page: <SubscribePage />,
  // },

  // profile
  {
    path: "/profile",
    page: <Profile />,
    path1: "/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/profile",
    page: <Profile />,
    path1: "/:tag1/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/profile/plan",
    page2: <ProfileContent />,
  },
  {
    path: "/:tag1/:tag2/:tag3/profile",
    page: <Profile />,
    path1: "/:tag1/:tag2/:tag3/profile/reset-password-by-old-password",
    page1: <ResetPasswordByOldPassword />,
    path2: "/:tag1/:tag2/:tag3/profile/plan",
    page2: <ProfileContent />,
  },
  // support
  {
    path: "/support",
    page: <Support />,
  },
  {
    path: "/:tag1/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/support",
    page: <Support />,
  },
  {
    path: "/:tag1/:tag2/:tag3/support",
    page: <Support />,
  },
  // forget-pass-web 忘记密码
  {
    path: "/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  {
    path: "/:tag1/:tag2/:tag3/forget-pass-web",
    page: <ForgetPassWeb />,
  },
  // 新增的页面
  {
    path: "/pdf-düzenleyici-pre",

    page: <PDFEdit />,
  },
  {
    path: ":tag/pdf-düzenleyici-pre",

    page: <PDFEdit />,
  },

  {
    path: "/pdf'yi-sıkıştır-pre",

    page: <CompressPDF />,
  },
  {
    path: ":tag/pdf'yi-sıkıştır-pre",

    page: <CompressPDF />,
  },

  {
    path: "/PDF-birleştirme-pre",

    page: <MergePDF />,
  },
  {
    path: ":tag/PDF-birleştirme-pre",

    page: <MergePDF />,
  },

  {
    path: "/pdf'yi-böl-pre",

    page: <SplitPDF />,
  },
  {
    path: ":tag/pdf'yi-böl-pre",

    page: <SplitPDF />,
  },

  {
    path: "/pdf'yi-döndür-pre",

    page: <RotatePDF />,
  },
  {
    path: ":tag/pdf'yi-döndür-pre",

    page: <RotatePDF />,
  },

  {
    path: "/pdf'yi-sil-pre",

    page: <DeletePDF />,
  },
  {
    path: ":tag/pdf'yi-sil-pre",
    page: <DeletePDF />,
  },

  {
    path: "/pdf-okuyucu-pre",

    page: <PDFReader />,
  },
  {
    path: ":tag/pdf-okuyucu-pre",

    page: <PDFReader />,
  },

  {
    path: "/pdf'den-kelimeye-pre",

    page: <PDFToWord />,
  },
  {
    path: ":tag/pdf'den-kelimeye-pre",

    page: <PDFToWord />,
  },

  {
    path: "/kelimeden-pdf'ye-pre",

    page: <WordToPDF />,
  },
  {
    path: ":tag/kelimeden-pdf'ye-pre",

    page: <WordToPDF />,
  },

  {
    path: "/ppt'den-pdf'ye-pre",

    page: <PPTToPDF />,
  },
  {
    path: ":tag/ppt'den-pdf'ye-pre",

    page: <PPTToPDF />,
  },

  {
    path: "/pdf'ye-excel-pre",

    page: <ExcelToPDF />,
  },
  {
    path: ":tag/pdf'ye-excel-pre",

    page: <ExcelToPDF />,
  },

  {
    path: "/pdf'den-jpg'ye-pre",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf'den-jpg'ye-pre",

    page: <PDFToJpg />,
  },

  {
    path: "/resmi-pdf'ye-çevir-pre",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/resmi-pdf'ye-çevir-pre",

    page: <ImageToPDF />,
  },

  {
    path: "/pdf'den-png'ye",

    page: <PDFToJpg />,
  },
  {
    path: ":tag/pdf'den-png'ye",

    page: <PDFToJpg />,
  },

  {
    path: "/png'den-pdf'ye",

    page: <ImageToPDF />,
  },
  {
    path: ":tag/png'den-pdf'ye",

    page: <ImageToPDF />,
  },

  //新增功能

  {
    path: "/pdf-ve-pdf-bir",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-ve-pdf-bir",
    page: <PDFToPDFA />,
  },
  {
    path: "/pdf-ve-pdf-bir-pre",
    page: <PDFToPDFA />,
  },
  {
    path: "/:tag1/pdf-ve-pdf-bir-pre",
    page: <PDFToPDFA />,
  },

  {
    path: "/pdf-ve-dosya",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-ve-dosya",
    page: <PDFToExcel />,
  },
  {
    path: "/pdf-ve-dosya-pre",
    page: <PDFToExcel />,
  },
  {
    path: "/:tag1/pdf-ve-dosya-pre",
    page: <PDFToExcel />,
  },

  {
    path: "/pdf-ve-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-ve-ptx",
    page: <PDFToPPTX />,
  },
  {
    path: "/pdf-ve-ptx-pre",
    page: <PDFToPPTX />,
  },
  {
    path: "/:tag1/pdf-ve-ptx-pre",
    page: <PDFToPPTX />,
  },

  {
    path: "/html-ve-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-ve-pdf",
    page: <HtmlToPDF />,
  },
  {
    path: "/html-ve-pdf-pre",
    page: <HtmlToPDF />,
  },
  {
    path: "/:tag1/html-ve-pdf-pre",
    page: <HtmlToPDF />,
  },
];
export default trRoutJSON;
