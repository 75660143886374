// 国际化插件
import i18n from "i18next";
// ICU格式化
import ICU from "i18next-icu";
// 后端资源加载
import Backend from "i18next-http-backend";
// 浏览器语言检测,配置参考: https://github.com/i18next/i18next-browser-languageDetector
import LanguageDetector from "i18next-browser-languagedetector";
// REACT支持
import { initReactI18next } from "react-i18next";
// 跨域存储语言检查器
import crossStorageDetector from "./crossStorageDetector";
export const loadLocale = async () => {
  // 获取浏览器语言检测器实例
  const languageDetector = new LanguageDetector();
  // 添加自定义检查器
  languageDetector.addDetector(await crossStorageDetector());
  // 配置国际化插件
  await i18n
    // 使用ICU格式
    .use(ICU)
    // 加载翻译, 文件地址/public/locales/{lng}/translation.json
    .use(Backend)
    // 检测用户语言
    // .use(languageDetector)
    // 初始化react-i18next
    .use(initReactI18next)
    // 初始化 i18next
    .init(
      {
        // 浏览器语言检测设置
        detection: {
          // 检测用户语言方法及顺序
          order: [
            "path",
            "crossStorageDetector",
            // "navigator",
            "querystring",
            "cookie",
            "localStorage",
            "sessionStorage",
            "htmlTag",
            "subdomain",
          ],
          caches: ["localStorage", "cookie"],
          cookieDomain: process.env.REACT_APP_ROOT_DOMAIN,
        },
        getAsync: false,
        // 翻译文件名称(命名空间)
        ns: ["translation"],
        // 默认翻译文件名称
        defaultNS: "translation",
        // 支持语言列表
        supportedLngs: [
          "af",
          "ar",
          "be",
          "de",
          "en",
          "fil",
          "fr",
          "ko",
          "lt",
          "ms",
          "nl",
          "pl",
          "pt",
          "ro",
          "ru",
          "sk",
          "sl",
          "sq",
          "sr",
          "sv",
          "ta",
          "th",
          "tr",
          "uk",
          "vi",
          "zh",
          "ja",
        ],
        // 支持语言变体
        nonExplicitSupportedLngs: true,
        // 加载指定语言
        // lng: 'en',
        // 如果用户语言的翻译不可用，则加载设置的语言,设置为false则不会加载
        fallbackLng: "en",
        // 调试模式
        debug: false,
        // debug: process.env.NODE_ENV == "development",
        // 插值
        interpolation: {
          // 转义值
          // REACT不需要这个属性
          escapeValue: false,
        },
      },
      // i18n插件初始化完成或异常时的回调函数
      (err, t) => {
        if (err) return log("something went wrong loading", err);
        t("key");
      },
    );
};

// 抛出插件
export default i18n;
